import React, { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import useTopic from "../hooks/useTopic";
import { logOut } from "../redux/actions/user";
import useGroup from "../hooks/useGroup";
import useUser from "../hooks/useUser";
import { formatBytes } from "../utill/helpers";
import User from "../page/User";
import { setPopup } from "../redux/actions/popup";

const Sidebar = ({ isDrawerOpen, setIsDrawerOpen, open, setOpen }) => {
  const { search, treeView } = useTopic();

  const { profile } = useUser();
  const { groups } = useGroup();
  const sidebar = useRef();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const downloadFile = () => {
    try {
      const url = "./extension.zip";
      console.log(url);
      const link = document.createElement("a");
      link.href = url;
      link.download = "extension.zip";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  const handleMenuItemClick = () => {
    if (open === 'image') {
      setOpen('');
    }
  };

  return (

    <div id="user-sidebar" className={`sidebar ${isDrawerOpen ? 'close' : ''}`} ref={sidebar}>
      <div className="sidebar-innerdiv d-flex flex-column">
        <div className="">
          <div className="attachment" onClick={() => setIsDrawerOpen(!isDrawerOpen)}>
            <i className="material-icons-sharp" id="navbar-toggle">
              navigate_next
            </i>
          </div>
          <Link to="/">
            <div className="logo-details">
              <i>
                <img src="/media/yourarchive_logo.png" />
              </i>
              <h2 className="mb-0">
                Your<span className="logo_name">Archiv</span>
              </h2>
            </div>
          </Link>
        </div>
        <ul
          className="sidebar-links"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            flex: 1,
          }}
        >
          <li className="sidetab d-flex" id="all">
            <ul className="p-0 w-100">
              <li className="sidebar-link d-flex align-items-center mb-1" title="All">
                <NavLink
                  to="/all"
                  style={({ isActive }) =>
                    isActive
                      ? {
                        color: "var(--bs-warning)",
                      }
                      : {}
                  }
                  onClick={() => handleMenuItemClick()}
                  className={`link d-flex align-items-center mb-1 w-100" ${isDrawerOpen ? 'flex-column text-center' : ''}`}
                >
                  <i className="material-icons-sharp" id="all_i">
                    apps
                  </i>
                  <span className="link_name" id="all_s">
                    All
                  </span>


                </NavLink>
              </li>
              <li className="sidebar-link d-flex align-items-center mb-1" title="Audio">
                <NavLink
                  to="/audio"
                  style={({ isActive }) =>
                    isActive
                      ? {
                        color: "var(--bs-warning)",
                      }
                      : {}
                  }
                  onClick={() => handleMenuItemClick()}
                  className={`link d-flex align-items-center mb-1 w-100" ${isDrawerOpen ? 'flex-column text-center' : ''}`}
                >
                  <i className="material-icons-sharp" id="audio_i">
                    library_music
                  </i>
                  <span className="link_name" id="all_s">
                    Audio
                  </span>
                </NavLink>
              </li>
              <li className="sidebar-link d-flex align-items-center mb-1" title="Check Later">
                <NavLink
                  to="/check_text"
                  style={({ isActive }) =>
                    isActive
                      ? {
                        color: "var(--bs-warning)",
                      }
                      : {}
                  }
                  onClick={() => handleMenuItemClick()}
                  className={`link d-flex align-items-center mb-1 w-100" ${isDrawerOpen ? 'flex-column text-center' : ''}`}
                >
                  <i className="material-icons-sharp" id="checktext_i">
                    pending_actions
                  </i>
                  <span className="link_name" id="all_s">
                    Check Later
                  </span>
                </NavLink>
              </li>
              <li className="sidebar-link" title="Text">
                <NavLink
                  to="/text"
                  style={({ isActive }) =>
                    isActive
                      ? {
                        color: "var(--bs-warning)",
                      }
                      : {}
                  }
                  onClick={() => handleMenuItemClick()}
                  className={`link d-flex align-items-center mb-1 w-100" ${isDrawerOpen ? 'flex-column text-center' : ''}`}
                >
                  <i className="material-icons-sharp" id="text_i">
                    description
                  </i>
                  <span className="link_name" id="all_s">
                    Text
                  </span>
                </NavLink>
              </li>
              <li className="sidebar-link d-flex align-items-center mb-1" title="Video">
                <NavLink
                  to="/video"
                  style={({ isActive }) =>
                    isActive
                      ? {
                        color: "var(--bs-warning)",
                      }
                      : {}
                  }
                  onClick={() => handleMenuItemClick()}
                  className={`link d-flex align-items-center mb-1 w-100" ${isDrawerOpen ? 'flex-column text-center' : ''}`}
                >
                  <i className="material-icons-sharp" id="video_i">
                    video_library
                  </i>
                  <span className="link_name" id="all_s">
                    Video
                  </span>
                </NavLink>
              </li>

              <li className="sidebar-link" title="Topic">
                <ul className="dropdown m-0 p-0 dropdown-ul btn-group w-100 link">
                  <li className="p-0 m-0 w-100 d-flex" >
                    <a
                      href="#"
                      onClick={() => handleMenuItemClick()}
                      className={`p-0 m-0 d-flex align-items-center ${isDrawerOpen ? 'flex-column text-center' : ''} ${location.pathname.startsWith("/topic")
                        ? "text-warning"
                        : ""
                        }`}
                    >
                      <i className={`material-icons-sharp`}>topic</i>
                      <span className="link_name">Topic</span>
                    </a>
                    <div style={{ flex: 1 }}></div>
                    <a
                      href="#"
                      onClick={() => handleMenuItemClick()}
                      className="link_name p-0 m-0 d-flex align-items-center"
                    >
                      <i
                        className="material-icons-sharp"
                        style={{
                          minWidth: "auto",
                          height: "16px",
                          fontSize: "16px",
                        }}
                      >
                        navigate_next
                      </i>
                    </a>
                    <ul className="dropdown-ul">
                      {NestedDropdown(treeView, navigate, "topic")}
                    </ul>
                  </li>
                </ul>
              </li>
              <li className="sidebar-link">
                <ul className="dropdown m-0 p-0 dropdown-ul btn-group w-100 link">
                  <li className="p-0 m-0 w-100 d-flex">
                    <a
                      href="#"
                      onClick={() => handleMenuItemClick()}
                      className={`p-0 m-0 d-flex align-items-center link  ${isDrawerOpen ? 'flex-column text-center' : ''} ${location.pathname.startsWith("/group")
                        ? "text-warning"
                        : ""
                        }`}
                    >
                      <i className="material-icons-sharp" title="Groups">group</i>
                      <span className="link_name" title="Groups" >Groups</span>
                    </a>
                    <div style={{ flex: 1 }}></div>
                    <a
                      href="#"
                      onClick={() => handleMenuItemClick()}
                      className="link_name p-0 m-0 d-flex align-items-center"
                    >
                      <i
                        className="material-icons-sharp"
                        style={{
                          minWidth: "auto",
                          height: "16px",
                          fontSize: "16px",
                        }}
                      >
                        navigate_next
                      </i>
                    </a>
                    <ul className="dropdown-ul">
                      {NestedDropdown(groups, navigate, "group")}
                    </ul>
                  </li>
                </ul>
              </li>

              {/* <li className="sidebar-link">
                <ul className="dropdown m-0 p-0 dropdown-ul btn-group w-100 link">
                  <li className={`p-0 m-0 w-100 d-flex ${isDrawerOpen ? 'flex-column text-center' : ''}`} title="Extension">
                    <i className="material-icons-sharp" onClick={downloadFile}>extension</i>
                    <span className="link_name" onClick={downloadFile}>
                      Extension
                    </span>
                  </li>
                </ul>
              </li> */}
              <li className="sidebar-link">
                <ul className="dropdown m-0 p-0 dropdown-ul btn-group w-100 link">
                  <li className={`p-0 m-0 w-100 d-flex ${isDrawerOpen ? 'flex-column text-center' : ''}`} title="Download">
                    <i className="material-icons-sharp" onClick={() => {
                      dispatch(setPopup({
                        type: "extensiondownloadpopup",
                        from: "article",
                      }))
                    }}>download</i>
                    <span className="link_name" onClick={() => {
                      dispatch(setPopup({
                        type: "extensiondownloadpopup",
                        from: "article",
                      }))
                    }}>
                      Download
                    </span>
                  </li>
                </ul>
              </li>


            </ul>
          </li>



          <li className="sidetab d-flex" id="all">
            <ul className="p-0 w-100">
              <li className="sidebar-link d-flex align-items-center mb-1" title="Upload">
                <NavLink
                  to="/upload"
                  style={({ isActive }) =>
                    isActive
                      ? {
                        color: "var(--bs-warning)",
                      }
                      : {}
                  }
                  onClick={() => handleMenuItemClick()}
                  className={`link d-flex align-items-center mb-1 w-100" ${isDrawerOpen ? 'flex-column text-center' : ''}`}
                >
                  <i className="material-icons-sharp">upload_file</i>
                  <span className="link_name" id="all_s">
                    Upload
                  </span>
                </NavLink>
              </li>
              <li className="sidebar-link d-flex align-items-center mb-1" title="Account">
                <a
                  href="#"
                  onClick={() => handleMenuItemClick()}
                  className={`link d-flex align-items-center mb-1 w-100" ${isDrawerOpen ? 'flex-column text-center' : ''}`}
                >
                  <i className="material-icons-sharp">account_circle</i>
                  <div className="link d-grid">
                    <span
                      className="link_name m-0"
                      id="all_s"
                      style={{ lineHeight: "12px" }}
                    >
                      Account{" "}
                      <span
                        style={{ fontSize: "9px" }}
                        className="text-warning link_name"
                      >
                        {formatBytes(profile.storage)}
                      </span>
                    </span>
                  </div>
                </a>
              </li>
              <li className="sidebar-link d-flex align-items-center mb-1" title="Profile">
                <NavLink
                  to="/profile"
                  style={({ isActive }) =>
                    isActive
                      ? {
                        color: "var(--bs-warning)",
                      }
                      : {}
                  }
                  onClick={() => handleMenuItemClick()}
                  className={`link d-flex align-items-center mb-1 w-100" ${isDrawerOpen ? 'flex-column text-center' : ''}`}
                >
                  <i className="material-icons-sharp">person</i>

                  <span className="link_name" id="all_s">
                    Profile
                  </span>
                </NavLink>
              </li>
              <li
                className={`sidebar-link d-flex align-items-center mb-1 link ${isDrawerOpen ? 'flex-column text-center' : ''}`} title="Log Out"
                onClick={() => dispatch(logOut(navigate))}
              >
                <i className="material-icons-sharp">logout</i>
                <span className="link_name" id="all_s">
                  Log Out
                </span>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  );
};
export default Sidebar;

const NestedDropdown = (treeView, navigate, href) => {
  return treeView?.map((v) => {
    if (v.children?.length) {
      return (
        <>
          <li>
            <a
              onClick={() => {
                navigate(`/${href}/${v.id}`);
              }}
              href="#"
            >
              {v.label || v.name} {" >"}
            </a>
            <ul className="dropdown-ul">
              {NestedDropdown(v.children, navigate, href)}
            </ul>
          </li>
        </>
      );
    } else {
      return (
        <>
          <li
            onClick={() => {
              navigate(`/${href}/${v.id}`);
            }}
          >
            <a href="#">{v.label || v.name}</a>
          </li>
        </>
      );
    }
  });
};
