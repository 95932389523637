/* eslint-disable jsx-a11y/img-redundant-alt */
import { Route, Routes, Navigate, useNavigate } from "react-router-dom"
import Listing from "../components/Listing"
import PopupsContainer from "../components/PopupsContainer"
import Sidebar from "../components/Sidebar"
import View from "../components/View"
import useAuth from "../hooks/useAuth"
import Profile from "./Profile"
import Upload from "./Upload"
import { useIdleTimer } from 'react-idle-timer'
import { logOut } from "../redux/actions/user"
import { useDispatch, useSelector } from "react-redux"
import { useState } from "react"
import * as constants from "../utill/constants"

const User = () => {
    const [isDrawerOpen, setIsDrawerOpen] = useState(true);
    const { load } = useAuth()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const article = useSelector(s => s.article)
    const [open, setOpen] = useState("")
    const handleOnIdle = event => {
        console.log("user is ideal");
        dispatch(logOut(navigate))
    }


    useIdleTimer({
        timeout: 1000 * 60 * 15,
        onIdle: handleOnIdle,
        debounce: 500,
    })

    if (load) return ""
    return (
        <div className="user-main-container">
            <PopupsContainer />
            <Routes>

                <Route index path="/*" element={
                    <>
                        <div className={`d-flex h-100 overflow-hidden ${isDrawerOpen ? '' : ''}`}>
                        <Sidebar isDrawerOpen={isDrawerOpen} setIsDrawerOpen={setIsDrawerOpen} open={open} setOpen={setOpen} />
                        <>
                            <Routes>
                                <Route path="/"
                                    element={<>
                                        <Navigate to={"all"} />
                                    </>}
                                />
                                <Route path="/:type"
                                    element={<>
                                        {article?.article.mediaUrl && article.article.mediaUrl.length > 0 &&
                                            <div style={{ flex: 1, overflow: 'auto', cursor: "pointer", display: open === "image" ? "block" : "none" }} onClick={() => setOpen("")}>
                                                <img src={constants.API_BASE_URL + article.article.mediaUrl[0].file_URL} height="auto" width="100%" alt="Image" />
                                            </div>
                                        }
                                        <Listing open={open} />
                                        <View open={open} setOpen={setOpen} />
                                    </>}
                                />
                                <Route path="/:type/:id"
                                    element={<>
                                        <Listing />
                                        <View />
                                    </>}
                                />
                                <Route path="/upload"
                                    element={<>
                                        <Upload />
                                    </>}
                                />
                                <Route path="/profile/*"
                                    element={<Profile />}
                                />
                            </Routes>

                        </>
                    </div>
                </>
                }>

            </Route>
        </Routes>
        </div >
    )
}
export default User