import axios from './axios';
import * as types from "../redux/types"


const article = {
    get: (query) => {
        return axios.get(`/articles?${query}`);
    },
    getById: (query) => {
        return axios.get(`/articles?${query}`);
    },
    add: (data, dispatch, controller) => {
        return axios({
            method: "post",
            url: `/article`,
            data,
            signal: controller.signal,
            onUploadProgress: e => {
                const percentCompleted = Math.round(
                    (e.loaded * 100) / e.total
                );
                dispatch({ type: types.SET_ARTICLE_UPLOAD_PROGRESS, payload: percentCompleted })
            }
        });
    },
    addTopic: (topic_id, articleId) => {
        return axios.post(`/add-to-topic/${articleId}`, { topic_id });
    },
    removeFromTopic: (topic_id, articleId) => {
        return axios.delete(`/remove-to-topic/${articleId}`, { data: { topic_id } });
    },
    shareToGroup: (group_id, article_id) => {
        return axios.post(`/share/${group_id}`, { article_id });
    },
    removeFromGroup: (group_id, article_id) => {
        return axios.delete(`/remove-article/${group_id}`, { data: { article_id } });
    },
    update: (id, data) => {
        return axios.patch(`/article/${id}`, data);
    },
    delete: (id) => {
        return axios.delete(`/article/${id}`);
    }
};

export default article