import * as Yup from "yup";
import * as constants from "../utill/constants"

const FILE_SIZE = 10000023460;
export const articleSchema = Yup.object().shape({
    file: Yup.mixed()
        .test("FILE_SIZE", "Uploaded file is too big.",
            value => !value || (value && value.size <= FILE_SIZE))
        .test("FILE_FORMAT", "Uploaded file has unsupported format.", value => !value || (value && constants.SUPPORTED_FORMATS.includes(value.type))),
    title: Yup.string().min(4).max(1024).required('Required'),
    description: Yup.string().min(4).required('Required'),
    url: Yup.string().max(1200),
    date: Yup.date(),
    topics: Yup.array().of(
        Yup.object().shape({
            value: Yup.string().required("Value required"),
            label: Yup.string()
        })
    ),
    groups: Yup.array().of(
        Yup.object().shape({
            value: Yup.string().required("Value required"),
            label: Yup.string()
        })
    )
});
export const editArticleSchema = Yup.object().shape({
    file: Yup.mixed()
        .test("FILE_SIZE", "Uploaded file is too big.",
            value => !value || (value && value.size <= FILE_SIZE))
        .test("FILE_FORMAT", "Uploaded file has unsupported format.", value => !value || (value && constants.SUPPORTED_FORMATS.includes(value.type))),
    title: Yup.string().min(4).max(1024),
    description: Yup.string().min(4),
    url: Yup.string(),
    date: Yup.date(),
});

export const loginSchema = Yup.object().shape({
    email: Yup.string().min(2).max(32).email('Invalid email').required('Required'),
    password: Yup.string().min(8).max(32).required('Required'),
});
export const SignupSchema = Yup.object().shape({
    // username: Yup.string()
    //     .min(2, 'Too Short!')
    //     .max(32, 'Too Long!'),
    email: Yup.string().min(2).max(32).email('Invalid email').required('Required'),
    password: Yup.string().max(32).required('Required').matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
        "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number"
      ),
    cPassword: Yup.string().min(8).max(32).required('Required').oneOf([Yup.ref('password'), null], 'Passwords must match'),
});