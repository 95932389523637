import { Route, Routes } from "react-router-dom"
import PopupsContainer from "./components/PopupsContainer"
import Auth from "./page/Auth"
import User from "./page/User"
import "./App.css"




const App = () => {
console.log('start1');
  return (<>
    <Routes>
      {/* public route */}
      <Route path="/new/*"
        element={<Auth />}
      />

      {/* private route */}
      <Route index path="/*"
        element={<User />}
      />

    </Routes>
  </>
  )
}
export default App