import React, { useEffect, useRef, useState } from 'react'
import ReactPlayer from 'react-player'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { setPopup } from '../redux/actions/popup'
import * as constants from "../utill/constants"
import { useFormik } from "formik";
import { editArticleSchema } from "../utill/formSchemas"
import articleAPI from "../api/article"
import { getArticle, getArticles, setArticle } from '../redux/actions/articles'
import { convertBitsToBytes } from '../utill/helpers'
import axiosCustom from '../api/axios'


const View = ({ setOpen, open }) => {
  const [edit, setEdit] = useState(false)
  const [att, setAtt] = useState({})
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const article = useSelector(s => s.article)
  const [size, setSize] = useState(0);

  const { type, id } = useParams();

  React.useEffect(() => {
    setEdit(false)
  }, [article.article])

  useEffect(() => {
    let sz = 0;
    article?.article?.mediaUrl?.map((media) => {
      sz += media.file_size
    })
    setSize(sz);


    if (article?.article?.mediaUrl) {
      article?.article?.mediaUrl?.sort((a, b) => new Date(a.created_at) - new Date(b.created_at))
    }



    // article?.article?.mediaUrl = article?.article?.mediaUrl?.sort((a, b) => new Date(a.created_at) - new Date(b.created_at));
  }, [article.article]);


  const formik = useFormik({
    initialValues: {
      title: article.article.title,
      description: article.article.description,
      date: new Date(article.article.date).toDateString(),
      file: null,
      url: article.article.url,
    },
    enableReinitialize: true,
    validationSchema: editArticleSchema,

    onSubmit: (values) => {
      function getFormData(object) {
        const formData = new FormData();
        Object.keys(object).forEach(key => formData.append(key, object[key]));
        return formData;
      }
      articleAPI.update(article.article.id, getFormData(values)).then(v => {
        console.log("updated", v);
        dispatch(getArticle(article.article.id))
        dispatch(getArticles())
        setEdit(false)
      }).catch(er => {
        console.error(er);
        if (er.response) {
          setAtt(er.response.data?.error)
        } else {
          setAtt({ message: "Something went wrong" })
        }
      })
    }
  })

  const handleRemoveFromtopic = (topic_id, article_id) => {
    articleAPI.removeFromTopic(topic_id, article_id).then(() => {
      dispatch(getArticle(article_id))
      dispatch(getArticles())
    }).catch(er => {
      console.error(er);
    })
  }
  const handleRemoveFromgroup = (group_id, article_id) => {
    articleAPI.removeFromGroup(group_id, article_id).then(() => {
      dispatch(getArticle(article_id))
      dispatch(getArticles())
    }).catch(er => {
      console.error(er);
    })
  }

  if (!Object.entries(typeof article.article === "object" ? article.article : {}).length) {
    if (window.innerWidth <= 420) {
      return
    } else {
      return
    }
  }

  const ShowImg = () => {
    const ref = useRef()
    const [load, setLoad] = useState(false)
    React.useEffect(() => {
      setLoad(true)
      ref.current?.addEventListener("load", (e) => {
        setLoad(false)
      })
    }, [article.article])

    return <>
      {
        article?.article?.mediaUrl.map(v => {
          return (
            <>
              <img ref={ref} style={{ cursor: "pointer", display: load ? "none" : "block" }} onClick={() => setOpen("image")} className="w-100 view-image" id="img-popup" src={constants.API_BASE_URL + v.file_URL}></img>
              <div style={{ display: !load ? "none" : "block" }}>
                <div class="spinner-border" role="status">
                  <span class="sr-only"></span>
                </div>
              </div>
            </>
          )
        })
      }


    </>
  }

  // console.log("this is what we need", article);

  // download record 
  const downloadExtensionFile = async (downloadRecordUrl, fileName) => {
    try {

      const url = downloadRecordUrl + "?download=1";
      const link = document.createElement("a");
      link.href = url;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };



  return (
    <form className={`right-view px-3 pt-4 py-3 text-bg-dark ${open ? "d-none" : ""}`} onSubmit={formik.handleSubmit}>

      <div className="mb-1">
        <i onClick={() => dispatch(setArticle({}))} className='material-icons-sharp' id="all_i" style={{ fontSize: '20px' }}>
          arrow_back
        </i>

        <div style={{ float: "right", display: "flex", justifyContent: "center", alignItems: "start" }}>
          {
            article?.article?.status === "publish" && <div className='text-[12px] d-flex justify-content-end'>
              <span className='text-muted'>Size :</span>
              <span>{convertBitsToBytes(size)}</span>
            </div>
          }


          <i className='material-icons-sharp mx-2 text-light' id="all_i" data-bs-toggle="dropdown" aria-expanded="false" >
            more_vert
          </i>
          <ul className="dropdown-menu">
            <li ><a className="dropdown-item" href="#" onClick={() => dispatch(setPopup({
              type: "topicsList",
              from: "view",
              data: article.article
            }))}>Topics <i className='material-icons-sharp' id="all_i" style={{ fontSize: '20px', float: "right" }}>
                topic
              </i> </a> </li>
            <li><a className="dropdown-item" href="#" onClick={() => dispatch(setPopup({
              type: "GroupsList",
              from: "view",
              data: article.article
            }))}>Groups <i className='material-icons-sharp' id="all_i" style={{ fontSize: '20px', float: "right" }}>
                group
              </i></a></li>
            <li><a className="dropdown-item" href="#" onClick={() => dispatch(setPopup({
              type: "createGroup",
              from: "create",
              data: {}
            }))}>Create Group<i className='material-icons-sharp' id="all_i" style={{ fontSize: '20px', float: "right" }}>
                group
              </i></a></li>
            {!edit ? <li><a className="dropdown-item" href="#" onClick={() => setEdit(true)}>Edit <i className='material-icons-sharp' id="all_i" style={{ fontSize: '20px', float: "right" }}>
              edit
            </i></a></li> : <li><a className="dropdown-item" href="#" onClick={() => setEdit(false)}>Cancel Edit <i className='material-icons-sharp' id="all_i" style={{ fontSize: '20px', float: "right" }}>
              close
            </i></a></li>}
            <li><a className="dropdown-item" href="#" onClick={() => {
              dispatch(setPopup({
                type: "deletePopup",
                from: "article",
                data: article.article
              }))

            }}>Delete <i className='material-icons-sharp' id="all_i" style={{ fontSize: '20px', float: "right" }}>
                delete
              </i></a></li>
          </ul>
        </div>
      </div>

      <div className="view-top d-flex gap-2 justify-content-between">
        {edit ? <div className="my-2">
          <label className="text-muted" htmlFor="title">Title</label>
          <input value={formik.values.title} onChange={formik.handleChange} className="view-heading edit-view-heading" type="text" id="title" name="title" />
          {formik.touched.title && <small className="errMsg">{formik.errors.title}</small>}
          {!formik.errors.title && att.title ? <small className="errMsg" style={{ textAlign: "end" }}>{att.title}</small> : ""}
        </div> : <>
          <h1 className="view-heading mb-3">{article.article.title}
          </h1>
        </>}
      </div>
      {edit ? <>
        <div className="my-2">
          <label className="text-muted" htmlFor="description">Description</label>
          <textarea value={formik.values.description} onChange={formik.handleChange} className="fs-6 text mb-2 edit-view-heading" id="description" name="description"></textarea>
          {formik.touched.description && <small className="errMsg">{formik.errors.description}</small>}
          {!formik.errors.description && att.description ? <small className="errMsg" style={{ textAlign: "end" }}>{att.description}</small> : ""}
        </div>
      </> : <><p className='fs-6 text mb-2'>{article.article.description}</p></>}

      {edit ? <><div className="my-2">
        <label className="text-muted" htmlFor="url">URL</label>
        <input value={formik.values.url} onChange={formik.handleChange} className="edit-view-heading" type="text" id="url" name="url" />
        {formik.touched.url && <small className="errMsg">{formik.errors.url}</small>}
        {att.url ? <small className="errMsg" style={{ textAlign: "end" }}>{att.url}</small> : ""}
      </div></> : <> <a href={article.article.url} target="__black" className="mb-1">{article.article.url}</a></>}


      <br />
      {edit ? <><div className="my-2">
        <label className="text-muted" htmlFor="date">Date Created</label>
        <div className="date-upload">
          <input value={formik.values.date} onChange={formik.handleChange} className="form-control" type="date" id="data" name="date" />
          {formik.touched.date && <small className="errMsg">{formik.errors.date}</small>}
          {att.date ? <small className="errMsg" style={{ textAlign: "end" }}>{att.date}</small> : ""}
        </div>
      </div></> : <>{article.article.date ? <small className="my-2">{(new Date(article.article.date)).toDateString()}</small> : ""}</>}

      <br />

      <label className="text-muted my-2" >Selections</label>
      <>{article.article ? <small className="my-2 px-0">{(article.article?.selections.map((item) => <ul className='px-0'><li className='' >{item.text}</li></ul>))}</small> : ""}</>
      <br />

      {article.article.topics?.length ? <p className="fw-bold m-0 mb-1">Topics : {article.article.topics.map(v => <div className="btn-group">
        <button data-bs-toggle="dropdown" aria-expanded="false" type="button" className="btn btn-outline-info btn-sm m-1">{v.title}</button>
        <ul className="dropdown-menu">
          <li><a className="dropdown-item" href="#" onClick={() => navigate(`/topic/${v.id}`)}>List Article</a></li>
          <li><a className="dropdown-item" href="#" onClick={() => handleRemoveFromtopic(v.id, article.article?.id)}>Remove</a></li>
        </ul>
      </div>)}</p> : ""}
      {article.article.groups?.length ? <p className="fw-bold">Groups : {article.article.groups.map(v => <div className="btn-group">
        <button data-bs-toggle="dropdown" aria-expanded="false" type="button" className="btn btn-outline-info btn-sm m-1">{v.name}</button>
        <ul className="dropdown-menu">
          <li><a className="dropdown-item" href="#" onClick={() => navigate(`/group/${v.id}`)}>List Article</a></li>
          <li><a className="dropdown-item" href="#" onClick={() => handleRemoveFromgroup(v.id, article.article?.id)}>Remove from group</a></li>
        </ul>
      </div>)}</p> : ""}

      {/* <div className='my-2 text-[12px] d-flex justify-content-end'>
        <span className='text-muted'>Size :</span>
        <span>{convertBitsToBytes(size)}</span>
      </div> */}

      <div className="my-4" style={{
        width: "80%",
        background: "var(--bs-warning)",
        height: "2px",
        margin: "auto",
        borderRadius: "50%"
      }}></div>


      {edit ? <>

        <div className="my-2">
          <label htmlFor="file" className="btn btn-primary w-100" >Select File</label>
          <input onChange={v => formik.setFieldValue("file", v.target.files[0])} className="d-none" id="file" name="file" type="file" />
          {formik.touched.file && <small className="errMsg">{formik.errors.file}</small>}
          {!formik.errors.file && att.file ? <small className="errMsg" style={{ textAlign: "end" }}>{att.file}</small> : ""}
        </div>
        {formik.values.file && <div className="file-preview">
          <p className="text-file m-0" id="filename">{formik.values.file?.name}</p>
        </div>}
      </> : <>

        <div className="view-content mt-3">
          {article.article.mediaUrl.map(item => {
            console.log('item>>>>>>>>', item);

            return (
              <div key={item.id}>

                {/* <div className='text-[12px] d-flex justify-content-end'>
              <span className='text-muted'>Size :</span>
              <span>{convertBitsToBytes(size)}</span>
            </div> */}


                {item.type && item.file_URL && constants.SUPPORTED_AUDIO_FORMATS.find(v => v.includes(item.type)) ? <div style={{ display: "flex", gap: "20px", justifyContent: "start", alignItems: "center" }}>
                  <audio controls src={constants.API_BASE_URL + item.file_URL}></audio>
                  <div className='text-[10px] d-flex justify-content-start'>
                    <span className='text-muted'>Size :</span>
                    <span>{convertBitsToBytes(item?.file_size)}</span>
                  </div>
                  <i className='material-icons-sharp ' id="all_i" title="Download Record" onClick={() => {
                    downloadExtensionFile(constants.API_BASE_URL + item.file_URL, item.file_name)
                  }}>download</i>
                </div> : ""}
                {item.type && item.file_URL && constants.SUPPORTED_VIDEO_FORMATS.find(v => v.includes(item.type)) ? <ReactPlayer width={"100%"} height="auto" controls url={constants.API_BASE_URL + item.file_URL} /> : ""}
                {item.type && item.file_URL && constants.SUPPORTED_IMAGE_FORMATS.find(v => v.includes(item.type)) ? <ShowImg /> : ""}

                {item.type && item.file_URL && constants.SUPPORTED_DOC_FORMATS.find(v => v.includes(item.type)) ?
                  <iframe src={constants.API_BASE_URL + item.file_URL} style={{ "width": "100%", "height": "800px" }}> </iframe>
                  : ""}
                {<p style={{ "diplay": "inline block%", }}> {item.comment}</p >}
              </div>
            )
          })}

          {/* {article.article.type && article.article.mediaUrl[0].file_URL && constants.SUPPORTED_AUDIO_FORMATS.find(v => v.includes(article.article.type)) ? <audio controls src={constants.API_BASE_URL + article.article.file_URL}></audio> : ""} */}
          {/* {article.article.type && article.article.file_URL && constants.SUPPORTED_VIDEO_FORMATS.find(v => v.includes(article.article.type)) ? <ReactPlayer width={"100%"} height="auto" controls url={constants.API_BASE_URL + article.article.file_URL} /> : ""} */}
          {/* {article.article.type && article.article.file_URL && constants.SUPPORTED_IMAGE_FORMATS.find(v => v.includes(article.article.type)) ?
            <>
              <ShowImg />
            </> : ""} */}

          {/* {article.article.type && article.article.file_URL && constants.SUPPORTED_DOC_FORMATS.find(v => v.includes(article.article.type)) ? */}
          {/* <iframe src={constants.API_BASE_URL + article.article.file_URL} style={{ "width": "100%", "height": "800px" }}></iframe>
            : ""} */}
        </div>
      </>}
      {edit ? <div className='w-100'>
        {article.uploadProgress ? <div className="progress my-2" role="progressbar" aria-label="Basic example" aria-valuenow={article.uploadProgress} aria-valuemin="0" aria-valuemax="100">
          <div className="progress-bar" style={{ "width": `${article.uploadProgress}%` }}>{article.uploadProgress}%</div>
        </div> : ""}
        <br />
        <button id="btn-upload" className="btn btn-secondary w-100" type="submit">SAVE</button>
        {article.errors?.createArticle ? <p className="error" style={{ textAlign: "end" }}>{att.message}</p> : ""}

        {article.success?.createArticle ? <p className="successMsg" style={{ textAlign: "center" }}>{article.success?.createArticle}</p> : ""}
      </div> : <></>}
    </form >
  )
}


export default View