import { useEffect, useMemo } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getTopics } from "../redux/actions/topic"
import { list_to_tree } from "../utill/helpers"

import * as types from "../redux/types"
const useTopic = () => {
    const { filter, topics } = useSelector(s => s.topic)
    const dispatch = useDispatch()
    const search = (text) => {
        dispatch({
            type: types.SET_TOPIC_FILTER,
            payload: {
                ...filter, q: text
            }
        })
    }
    useEffect(() => {
        dispatch(getTopics())
    }, [filter])
    const treeView = useMemo(() => list_to_tree(topics), [topics])

    return { search, topics, treeView }
}
export default useTopic