import axios from './axios';


const topic = {
    get: (q) => {
        return axios.get(`/topics?${q}`);
    },
    getMy: (q) => {
        return axios.get(`account/topics?${q}`);
    },
    add: (data) => {
        return axios.post(`/topic`,
            data
        );
    },
    update: (id, data) => {
        return axios.patch(`/topic/${id}`, data);
    },
    delete: (id) => {
        return axios.delete(`/topic/${id}`);
    },
};

export { topic };