import { useDispatch, useSelector } from "react-redux"
import { loadMore, searchArticles, searchForUrlDomain, setArticle, setarticlePage, setArticlesType } from "../redux/actions/articles"
import * as helpers from "../utill/helpers"
import * as constants from "../utill/constants"
import { useEffect, useRef } from "react"
import { useNavigate } from "react-router-dom"

const List = () => {
    const loadmore = useRef()
    const navigate = useNavigate()
    const mainListContainer = useRef()
    const dispatch = useDispatch()
    const articles = useSelector(s => s.article.articles)
    const filter = useSelector(s => s.article.filter)
    const article = useSelector(s => s.article.article)


    useEffect(() => {
        // loadmore.current.addEventListener("click", (e) => {
        //     console.log(articles.pagination);
        //     if (articles.pagination)
        //         dispatch(loadMore(articles.pagination ? articles.pagination.current_page + 1 : 1))
        // })
        // mainListContainer.current.addEventListener("scroll", (e) => {
        //     console.log(listEnd.current.getBoundingClientRect().y, helpers.getInnerHeight(mainListContainer.current));
        //     if (listEnd.current.getBoundingClientRect().y === helpers.getInnerHeight(mainListContainer.current)) {
        //             dispatch(loadMore(articles.pagination?.current_page + 1))
        //     }
        // })
    }, [articles])

    console.log("articles ====>", articles)


    return (
        <div className="list-container py-3 px-3" id="main-list-container" ref={mainListContainer}>
            <ul className="article-list p-0 d-flex gap-3 flex-wrap justify-content-center">
                {
                    articles?.items?.map(v => <li key={v.id} onClick={() => dispatch(setArticle(v))} className={`w-100 ${article.id === v.id ? "active" : ""}`}>
                        <div className="card card-content" style={{ "maxWidth": "54rem", background: "#f7f0ff", color: "#222211", boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px", marginInline: "auto" }}>
                            <div className="card-body">
                                <h5 className="card-title d-flex align-items-center gap-1">

                                    {
                                        v?.status === 'draft' ? <i class="material-icons-sharp" id="checktext_i">pending_actions</i> : <>
                                            {v?.type && constants.SUPPORTED_AUDIO_FORMATS.find(vd => vd.includes(v.type)) ? <i className='material-icons-sharp' id="audio_i">library_music</i> : ""}
                                            {v?.type && constants.SUPPORTED_VIDEO_FORMATS.find(vd => vd.includes(v.type)) ? <i className='material-icons-sharp' id="video_i">video_library</i> : ""}
                                            {v?.type && constants.SUPPORTED_IMAGE_FORMATS.find(vd => vd.includes(v.type)) ? <i className='material-icons-sharp' id="text_i">description</i> : ""}
                                            {v?.type && constants.SUPPORTED_DOC_FORMATS.find(vd => vd.includes(v.type)) ? <i className='material-icons-sharp' id="text_i">article</i> : ""}
                                        </>
                                    }


                                    {filter.q ? helpers.getHighlightedText(v.title, filter.q) : v.title}</h5>

                                <p className="card-text text-clamp-7">{filter.q ? helpers.getHighlightedText(v.description, filter.q) : v.description}</p>

                                {/* <p className="card-text text-clamp-7">{filter.q ? helpers.getHighlightedText(v.selections.map((item) => item?.text), filter.q) : v.selections.map((item) => item.text)}</p> */}

                                <div className="d-flex justify-content-between">

                                    {v?.url ? <a className="card-link text-clamp-1 btn btn-outline-info px-3" onClick={(e) => { e.stopPropagation(); dispatch(setArticlesType({ "filters[url]": v.url })) }}>{v.url.replace(/.+\/\/|www.|\..+/g, '')}</a> : <div></div>}
                                    {v?.date ? <span>{new Date(v.date).toDateString()}</span> : ""}

                                </div>
                            </div>
                        </div>
                    </li>)
                }


                <li  >{articles.pagination?.current_page === articles.pagination?.total_pages ? "No More Items" : <>
                    {articles.items?.length ? <button className="btn btn-info" ref={loadmore} onClick={() => dispatch(loadMore(articles.pagination.current_page + 1))}>Load More</button> : 'No Items'}

                </>}</li>
            </ul>

        </div>
    )
}
export default List


































// import { useDispatch, useSelector } from "react-redux"
// import { loadMore, setArticle, setarticlePage } from "../redux/actions/articles"
// import * as helpers from "../utill/helpers"
// import * as constants from "../utill/constants"

// const List = () => {
//     const dispatch = useDispatch()
//     const articles = useSelector(s => s.article.articles)
//     const filter = useSelector(s => s.article.filter)
//     const article = useSelector(s => s.article.article)


//     return (
//         <div className="list-container p-2">
//             <ul className="article-list p-0">
//                 {articles.items?.map(v => <li key={v.id} onClick={() => dispatch(setArticle(v))} className={`list-item p-1 ${article.id === v.id ? "active" : ""}`}>
//                     <div className="d-flex gap-1 card-content">
//                         <div className="p-1  w-100">
//                             <div className="d-flex ">
//                                 {constants.SUPPORTED_AUDIO_FORMATS.find(vd => vd.includes(v.type)) ? <i className='material-icons-sharp' id="audio_i">library_music</i> : ""}
//                                 {constants.SUPPORTED_VIDEO_FORMATS.find(vd => vd.includes(v.type)) ? <i className='material-icons-sharp' id="video_i">video_library</i>: ""}
//                                 {constants.SUPPORTED_IMAGE_FORMATS.find(vd => vd.includes(v.type)) ? <i className='material-icons-sharp' id="text_i">description</i> : ""}

//                                 <h2 className="card-heading">{helpers.getHighlightedText(v.title, filter.q)}</h2>
//                                 <div className="card-date">
//                                     <p className="mb-1">{(new Date(v.created_at)).toLocaleString()}</p>
//                                     {/* <p className="mb-1">23:00:00</p> */}
//                                 </div>
//                             </div>
//                             <p className="card-desc text-start">
//                                 {helpers.getHighlightedText(v.description, filter.q)}
//                             </p>
//                             <a className="card-url" href={v.url} target="__black">{helpers.getHighlightedText(v.url, filter.q)}</a>
//                         </div>
//                     </div>
//                 </li>)}
//                 {/* <button type="button" onClick={() => dispatch(loadMore())}> load more</button> */}
//             </ul>
//         </div >
//     )
// }
// export default List