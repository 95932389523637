import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom"
import { loginUser, verifyloginUser } from "../redux/actions/user";
import React, { useEffect } from "react";
import { loginSchema } from "../utill/formSchemas";

const Login = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const user = useSelector(state => state.user)

    
    const search = useLocation().search;
    const token = new URLSearchParams(search).get("verifylogin");

    useEffect(() => {
        if (token) {
            dispatch(verifyloginUser({'token':token}))
        }
    },[]);
    

    const formik = useFormik({
        initialValues: {
            email: '',
            password: '',
        },
        enableReinitialize: true,
        validationSchema: loginSchema,
        onSubmit: (values) => {
            dispatch(loginUser(values))
        }
    })

    React.useEffect(() => {
        if (user.success.login) {
            navigate("/")
        }
    }, [user.success])

    return (
        <div className="auth-background d-flex flex-colum align-items-center justify-content-center">
            <div className="logo mb-4">
                <div className="logo-details">
                    <img src="/media/yourarchive_logo.png" />
                    <h2 className="mb-0 mx-1">Your<span className="logo_name">Archiv</span>
                    </h2>
                </div>
            </div>

            <form className="auth-container text-dark" onSubmit={(e) => formik.handleSubmit(e)}>
                <div className="auth-left"></div>
                <div className="auth-right">
                    <h3 className="text-warning">Login</h3>
                    <p>Login to your account to view your saved articles.</p>
                    <input autoComplete="off" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.email} className="auth-input" password="auth-input" id="email" name="email" type="text" placeholder="Email" />
                    {formik.touched.email && <small className="errMsg">{formik.errors.email}</small>}
                    <input autoComplete="off" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.password} className="auth-input" id="password" name="password" type="password" placeholder="Password" />
                    {formik.touched.password && <small className="errMsg">{formik.errors.password}</small>}
                    <div className="d-flex justify-content-end my-3">
                        <button type="submit" className="btn btn-warning" id="login_btn">Login</button>
                    </div>
                    <p className="error">{user.errors.login?.message}</p>
                    <p >Don't have an account? <span className="link-button" onClick={() => { navigate('../register') }}>Register</span></p>
                    <Link className="link-button" to={"/new/forgot"}>Forgot password</Link>
                </div>
            </form>
        </div>
    )
}
export default Login