import React from "react"
import { useDispatch, useSelector } from "react-redux";
import { getGroups } from "../redux/actions/group";

const useGroup = () => {
    const dispatch = useDispatch()
    const { group,
        groups,
        loading,
        error } = useSelector(s => s.group);

    React.useEffect(() => {
        dispatch(getGroups())
    }, [])

    return {
        group,
        groups,
        loading,
        error
    }

}
export default useGroup