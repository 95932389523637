import * as types from "../types"

const INITIAL_STATE = {
    popup: {
    },
}

const popup = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case types.SET_POPUP:
            return { ...state, popup: action.payload }
        case types.RESET_POPUP:
            return INITIAL_STATE
        case types.RESET_ALL_STATE:
            return INITIAL_STATE
        default:
            return state;
    }
}

export default popup

//type:"groupupdateonlogin"