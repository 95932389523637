import axios from "axios"
import React, { useState } from "react"

const useCountries = () => {
    const [countries, setCountries] = useState([])
    React.useEffect(() => {
        axios.get("/media/countries.json").then(v => setCountries(v.data))
    },[])
    
    return {countries}
}
export default useCountries