import { useFormik } from "formik";
import { useState } from "react";
import * as Yup from "yup";
import { topic } from "../api/topic";
const CreateTopic = () => {
    const [err, setErr] = useState({})
    const [success, setSuccess] = useState({})
    const topicSchema = Yup.object().shape({
        // title: Yup.string().min(2).max(32).required('Required'),
        description: Yup.string().min(8).max(1024).required('Required'),
    });

    const formik = useFormik({
        initialValues: {
            title: '',
            description: '',
        },
        enableReinitialize: true,
        validationSchema: topicSchema,

        onSubmit: (values, { resetForm }) => {
            setErr({})
            setSuccess({})
            topic.add(values).then(v => {
                setSuccess({ message: "Topic created successfully" })
            }).catch(er => { setErr(er.response?.data.error) }).finally((e) => {
                console.warn(e);
                setTimeout(()=>{
                    setErr({})
                    setSuccess({})
                },3000)
                resetForm()
            })
        }
    })

    return (
        <form className="g-3" onSubmit={formik.handleSubmit}>
            <div className="mb-3">
                <label htmlFor="name" className="form-label">Topic Name</label>
                <input value={formik.values.title} onChange={formik.handleChange} type="text" className="form-control" id="title" name="title" placeholder="Topic Name" />
                {formik.touched.title && <small className="errMsg">{formik.errors.title}</small>}
                {err.title ? <small className="error" style={{ textAlign: "end" }}>{err.title}</small> : ""}
            </div>
            <div className="mb-3">
                <label htmlFor="description" className="form-label">Topic Description</label>
                <textarea value={formik.values.description} onChange={formik.handleChange} className="form-control" id="description" name="description" rows="3" placeholder="Topic Description"></textarea>
                {formik.touched.description && <small className="errMsg">{formik.errors.description}</small>}
                {err.description ? <small className="error" style={{ textAlign: "end" }}>{err.description}</small> : ""}
            </div>
            <div className="mb-3">
                <button type="submit" className="btn btn-primary">Create Topic</button>
                {success.message ? <p className="successMsg" style={{ textAlign: "center" }}>{success.message}</p> : ""}
            </div>
        </form>
    )
}
export default CreateTopic