import * as types from "../types"

export const setPopup = (payload) => {
    return dispatch => {
        dispatch({
            type: types.SET_POPUP,
            payload
        })
    }
}
export const removePopup = () => {
    return {
        type: types.SET_POPUP,
        payload: {}
    }
}