import * as types from "../types"

const INITIAL_STATE = {
    group: {},
    groups: [],
    loading: false,
    error: null
}

const group = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case types.SET_GROUP:
            return { ...state, group: action.payload, loading: false, error: null }
        case types.SET_GROUPS:
            return { ...state, groups: action.payload, loading: false, error: null }
        case types.SET_GROUPS_LOADING:
            return { ...state, loading: true }
        case types.SET_GROUPS_ERROR:
            return { ...state, error: action.payload, loading: false }
        case types.RESET_ALL_STATE:
            return INITIAL_STATE
        default:
            return state;
    }
}

export default group