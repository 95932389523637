import { useFormik } from "formik";
import { useState } from "react";
import Select from "react-select";
import * as Yup from "yup";
import group from "../api/group";
import useUser from "../hooks/useUser";

const membersSchema = Yup.object().shape({
    member_IDs: Yup.array().required("Please add at least one member").of(
        Yup.object().shape({
            value: Yup.string().required("Value required"),
            label: Yup.string()
        })
    ),
});

const MemberList = ({ popup }) => {
    const [groupData, setGroupData] = useState(popup.data)
    const { users, search } = useUser()
    const members = groupData ? groupData.group_members : []
    const formik = useFormik({
        initialValues: {
            member_IDs: [],
        },
        enableReinitialize: true,
        validationSchema: membersSchema,

        onSubmit: (values, { resetForm, setErrors }) => {
            group.addMembers(groupData?.id, values.member_IDs.map(vm => vm.value)).then(v => {
                group.getById(groupData?.id).then(v => setGroupData(v.data[0]))
                resetForm()
            }).catch(er => {
                resetForm()
                if (er.response) {
                    setErrors({ ...er.response.data?.error })
                } else {
                    setErrors({
                        message: "something went wrong!"
                    })
                }

            })
        }
    })
    const removeFromGroup = (id) => {
        group.deleteMember({
            group_id: groupData?.id,
            member_id: id
        }).then(vdm => {
            group.getById(groupData?.id).then(v => setGroupData(v.data[0]))
        }).catch(er => {
            alert(er.response?.data.error.message)
        })
    }

    console.log("members ==>>>", members)
    return (
        <>
            <ul className="p-0">
                {members.map(v => (
                    <><li className="d-flex align-items-center justify-content-between mb-2">
                        <div className="">
                            <h4 className="fs-6 mb-1 text-warning"> {v.first_name} {v.last_name}</h4>
                            <p className="m-0 fs-5 fw-light">{v.email}</p>
                        </div>
                        <div className="d-flex gap-2">
                            <i className='material-icons-sharp ' id="all_i" title="Remove Member from group" onClick={() => removeFromGroup(v.id)}>delete</i>
                        </div>
                    </li>
                        <li><hr /></li>
                    </>

                ))}

                <li>
                    <form className="d-flex flex-wrap" onSubmit={formik.handleSubmit}>
                        <div className="mb-3 w-100">
                            <label htmlFor="member_IDs" className="labels">{popup.from === "edit" ? "Edit" : "Add"} Members</label>
                            <Select
                                className="basic-single"
                                classNamePrefix="select"
                                isMulti
                                isSearchable={true}
                                name="member_IDs"
                                options={users.map(v => ({ value: v.id, label: v.email })).filter(v => !members.find(mv => mv.member_id === v.value))}
                                value={formik.values.member_IDs}
                                onChange={v => formik.setFieldValue("member_IDs", v)}
                                onInputChange={q => search(q)}
                            />
                            {formik.touched.member_IDs && <small className="errMsg">{formik.errors.member_IDs}</small>}
                        </div>
                        <div className="w-100 text-center">
                            {<small className="errMsg">{formik.errors.message}</small>}
                        </div>
                        <button type="submit" class="btn btn-primary m-auto">Add Members</button>
                    </form>
                </li>
            </ul>
        </>
    )
}
export default MemberList