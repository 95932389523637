import { useDispatch } from "react-redux"
import { setPopup } from "../redux/actions/popup"
import GroupsList from "./GroupsList"

const Groups = () => {
    const dispatch=useDispatch()
    return (
        <div className="my-2">
            <div className="d-flex justify-content-between my-2 align-items-center">
                <h5 className="m-0">Your Groups</h5><button className="btn btn-success" onClick={() => dispatch(setPopup({
                    type: "createGroup",
                    from: "create",
                    data: {}
                }))}>Create group</button>
            </div>
            <GroupsList />
        </div >
    )
}
export default Groups