import * as types from "../types"

const INITIAL_STATE = {
    article: {},
    articles: {},
    page: {
        "total_items": 0,
        "per_page": 5,
        "total_pages": 0,
        "current_page": 1
    },
    filter: {
        q: "",
        sort: "",
        ['filters[type]']: "",
    },
    uploadProgress: 0,
    success: {},
    loading: false,
    errors: {}
}

const article = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case types.SET_ARTICLE:
            return { ...state, article: action.payload, loading: false }
        case types.SET_ARTICLES:
            return { ...state, articles: action.payload, loading: false }
        case types.SET_page:
            return { ...state, page: { ...state.page, } }
        case types.SET_FILTER:
            return { ...state, filter: action.payload }
        case types.SET_ARTICLE_LOADING:
            return { ...state, loading: true }
        case types.SET_ARTICLE_ERROR:
            return { ...state, errors: action.payload, loading: false }
        case types.SET_ARTICLE_SUCCESS:
            return { ...state, success: action.payload, loading: false }
        case types.SET_ARTICLE_UPLOAD_PROGRESS:
            return { ...state, uploadProgress: action.payload }
        case types.RESET_ALL_STATE:
            return INITIAL_STATE
        default:
            return state;
    }
}

export default article