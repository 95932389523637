export const getHighlightedText = (text, highlight) => {
    try {
        const highlightArr = highlight.split(" ");
        const parts = splitMultiple(text, highlightArr)
        console.log(parts);
        return <span> {parts.map((part, i) => {
            if (highlightArr.find(v => v.toLowerCase() === part.toLowerCase())) {
                return <span key={i} style={{ fontWeight: 'bold', color: "#f0a500" }}>
                    {part}
                </span>
            } else {
                return <span key={i}>
                    {part}
                </span>
            }
        }
        )} </span>;
    } catch (error) {
        const parts = text.split(highlight);
        return <span> {parts.map((part, i) =>
            <span key={i} style={part.toLowerCase() === highlight.toLowerCase() ? { fontWeight: 'bold', color: "#f0a500" } : {}}>
                {part}
            </span>)
        } </span>;
    }
}
export const splitMultiple = (str, separators) => {
    let q = ""
    separators.forEach((v, i, arr) => {
        q = q + v
        if (arr.length - 1 !== i)
            q = q + "|"
    })
    return str.split(new RegExp(`(${q})`, 'gi'))
}


export const list_to_tree = (list) => {
    var map = {}, node, roots = [], i;

    for (i = 0; i < list.length; i += 1) {
        map[list[i].id] = i; // initialize the map
        list[i].children = []; // initialize the children
    }

    for (i = 0; i < list.length; i += 1) {
        node = list[i];
        node.label = node.title
        node.value = node
        if (node.parent_id) {
            // if you have dangling branches check that map[node.parentId] exists
            list[map[node.parent_id]]?.children.push(node);
        } else {
            roots.push(node);
        }
    }
    return roots;
}
export const formatBytes = (bytes, decimals = 2) => {
    if (!+bytes) return '0 Bytes'

    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}
export const getInnerHeight = (elm) => {
    var computed = getComputedStyle(elm),
        padding = parseInt(computed.paddingTop) + parseInt(computed.paddingBottom);

    return elm.clientHeight - padding
}



export function convertBitsToBytes(bytes) {
    // 1 byte = 8 bits
    // const bytes = bits / 8;

    // 1 kilobyte (KB) = 1024 bytes
    const kilobytes = bytes / 1024;

    // 1 megabyte (MB) = 1024 kilobytes
    const megabytes = kilobytes / 1024;

    let resultValue, resultUnit;

    if (megabytes >= 1) {
        // Round up to one decimal place
        resultValue = megabytes.toFixed(1);
        resultUnit = 'MB';
    } else {
        // Convert to KB and round up to one decimal place
        resultValue = kilobytes.toFixed(1);
        resultUnit = 'KB';
    }

    return `${resultValue} ${resultUnit}`;
}