import { combineReducers } from 'redux';
import user from "./user"
import article from "./article"
import group from "./group"
import topic from "./topic"
import popup from "./popup"


const rootReducer = combineReducers({
    topic, group, article, user,popup
});

export default rootReducer;