import axios from "axios";
import {API_BASE_URL} from "../utill/constants"

// const BASE_URL = "http://localhost:5200/api";
const BASE_URL = API_BASE_URL;

const axiosCustom = axios.create({
    baseURL: BASE_URL,
    withCredentials: true,
});

axiosCustom.interceptors.response.use(
    response => response,
    async (error) => {
        if (error?.response?.status === 401) {
            window.location.href = "/new/login"
        }
        return Promise.reject(error);
    }
);
export default axiosCustom

