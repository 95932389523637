import axios from './axios';


const user = {
    register: data => axios.post(`/register`, data),
    resetEmail: data => axios.post(`/reset-password-email`, data),
    resetPass: data => axios.post(`/update-password`, data),
    login: data => axios.post(`/login`, data),
    verifylogin: data => axios.post(`/verifylogin`, data),
    logout: () => axios.get(`/logout`),
    me: () => axios.get(`/me`),
    getUsers: (q) => axios.get(`/users?q=${q}`),
    getUserById: (id) => axios.get(`/user/${id}`),
    deleteMe: () => axios.delete(`/user`),
    updateMe: (data, id) => axios.patch(`/user/update-profile`, data),
};

export default user;