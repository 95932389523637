import useTopic from "../hooks/useTopic"
import { getTopics } from "../redux/actions/topic";
import { topic } from "../api/topic";
import React, { useEffect, useLayoutEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { ReactTreeList } from "@bartaxyz/react-tree-list";
import { useFormik } from "formik";
import * as Yup from "yup";
import article from "../api/article";
import { getArticle, getArticles } from "../redux/actions/articles";

const TopicsList = ({ popup }) => {
    const { search, treeView } = useTopic();
    const [att, setAtt] = useState({})
    const [update, setupdate] = useState(false)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const handleAdd = (id) => {
        if (id) {
            console.log(popup);
            if (popup) {
                article.addTopic([id], popup.data.id).then(v => {
                    dispatch(getArticle(popup.data.id))
                    dispatch(getArticles())
                    document.getElementById("topicsListbtnClose").click()
                    // navigate(`/topic/${id}`)
                })
            }
        } else {
            setAtt({
                alert: {
                    type: "warning",
                    message: "Please select any topic"
                }
            })
        }
    }

    // new code

    const [data, setData] = useState(treeView);
    React.useEffect(() => {
        setData(treeView)
    }, [treeView])
    const [create, setCreate] = useState(false);
    const [selected, setSelected] = useState({});
    const onTreeListChange = (data) => {
        setData(data);
    };

    const onTreeListSelected = (item) => {
        setSelected(item);
        const topicListDiv = document.querySelector('.topic-list');

        // Add the extra class
        topicListDiv.classList.remove('none-selected');
    };

    const onDrop = (dragingNode, dragNode, drogType) => {
        console.log("dragingNode", dragingNode);
        console.log("dragNode", dragNode);
        console.log("drogType", drogType);
        const parent_id = drogType === "before" ? '' : dragNode.id
        topic.update(dragingNode.id, { parent_id, title: dragingNode.label }).then(() => {
            dispatch(getTopics())
        })
    };
    const [err, setErr] = useState({})
    const [success, setSuccess] = useState({})

    const topicSchema = Yup.object().shape({
        title: Yup.string().min(2).max(32).required('Required'),
        description: Yup.string().min(8).max(1024),
    });

    const formik = useFormik({
        initialValues: {
            title: '',
            description: '',
        },
        enableReinitialize: true,
        validationSchema: topicSchema,

        onSubmit: (values, { resetForm }) => {
            setErr({})
            setSuccess({})
            if (update && selected) {
                topic.update(selected.id, values).then(v => {
                    dispatch(getTopics())
                    setSelected(v)
                    setSuccess({ message: "Topic updated successfully" })
                }).catch(er => { setErr(er.response?.data.error) }).finally((e) => {
                    console.warn(e);
                    setTimeout(() => {
                        setErr({})
                        setSuccess({})
                    }, 3000)
                    resetForm()
                })
            } else {
                if (selected) {
                    console.log(selected);
                    values.parent_id = selected.id
                }
                topic.add(values).then(v => {
                    dispatch(getTopics())
                    setSuccess({ message: "Topic created successfully" })
                }).catch(er => { setErr(er.response?.data.error) }).finally((e) => {
                    setTimeout(() => {
                        setErr({})
                        setSuccess({})
                    }, 3000)
                    resetForm()
                })
            }
        }
    })
    React.useEffect(() => {
        if (update) {
            formik.setFieldValue("description", selected.description)
            formik.setFieldValue("title", selected.label)
        } else {
            formik.setFieldValue("description", "")
            formik.setFieldValue("title", "")
        }
    }, [update, selected, create])


    const handleDelete = (selected) => {
        topic.delete(selected.id).then(v => {
            dispatch(getTopics())
        })
    }

    const clearAllSelection = () => {
        console.log('treeView', treeView.length);
        for (let tindex = 0; tindex < treeView.length; tindex++) {
            delete treeView[tindex]['selected'];
        }        
        // delete treeView[1]['selected'];

        for (let dindex = 0; dindex < data.length; dindex++) {
            delete data[dindex]['selected'];
        } 
        // delete data[1]['selected'];
        setData(data);
        setSelected({});

        const topicListDiv = document.querySelector('.topic-list');

        // Add the extra class
        topicListDiv.classList.add('none-selected');

    }

    return (
        <>
            {Object.entries(selected).length === 0 ? '' : <button onClick={clearAllSelection} className="btn btn-link">
            <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M7.23442 17.8482L7.61748 16.9245C7.734 16.9728 7.86257 17 8 17H9V18H8C7.72882 18 7.47024 17.946 7.23442 17.8482ZM15 18V17H16C16.1374 17 16.266 16.9728 16.3825 16.9245L16.7656 17.8482C16.5298 17.946 16.2712 18 16 18H15ZM18 9H17V8C17 7.86257 16.9728 7.734 16.9245 7.61748L17.8482 7.23442C17.946 7.47024 18 7.72882 18 8V9ZM9 6H8C7.72882 6 7.47024 6.05397 7.23442 6.15176L7.61748 7.07549C7.734 7.02717 7.86257 7 8 7H9V6ZM6 15H7V16C7 16.1374 7.02717 16.266 7.07549 16.3825L6.15176 16.7656C6.05397 16.5298 6 16.2712 6 16V15ZM6 13H7V11H6V13ZM6 9H7V8C7 7.86257 7.02717 7.734 7.07549 7.61748L6.15176 7.23442C6.05397 7.47024 6 7.72882 6 8V9ZM11 6V7H13V6H11ZM15 6V7H16C16.1374 7 16.266 7.02717 16.3825 7.07549L16.7656 6.15176C16.5298 6.05397 16.2712 6 16 6H15ZM18 11H17V13H18V11ZM18 15H17V16C17 16.1374 16.9728 16.266 16.9245 16.3825L17.8482 16.7656C17.946 16.5298 18 16.2712 18 16V15ZM13 18V17H11V18H13Z"
                    fill="black"
                    fillOpacity="0.5"
                />
            </svg>
            </button> }
            
            <button id="topicsListbtnClose" style={{ display: "none" }} type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            {att.alert ? <div className={`alert alert-${att.alert?.type} alert-dismissible fade show`} role="alert" onClick={() => setAtt(o => ({ ...o, alert: null }))}>
                {att.alert?.message}
                <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
            </div> : ""}

            <div className="topic-list">
            <ReactTreeList
                key={"wsedrfghjm,"}
                data={data}
                draggable={true}
                onDrop={onDrop}
                onChange={onTreeListChange}
                itemDefaults={{
                    open: false, arrow: "▸", icon: <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M7.23442 17.8482L7.61748 16.9245C7.734 16.9728 7.86257 17 8 17H9V18H8C7.72882 18 7.47024 17.946 7.23442 17.8482ZM15 18V17H16C16.1374 17 16.266 16.9728 16.3825 16.9245L16.7656 17.8482C16.5298 17.946 16.2712 18 16 18H15ZM18 9H17V8C17 7.86257 16.9728 7.734 16.9245 7.61748L17.8482 7.23442C17.946 7.47024 18 7.72882 18 8V9ZM9 6H8C7.72882 6 7.47024 6.05397 7.23442 6.15176L7.61748 7.07549C7.734 7.02717 7.86257 7 8 7H9V6ZM6 15H7V16C7 16.1374 7.02717 16.266 7.07549 16.3825L6.15176 16.7656C6.05397 16.5298 6 16.2712 6 16V15ZM6 13H7V11H6V13ZM6 9H7V8C7 7.86257 7.02717 7.734 7.07549 7.61748L6.15176 7.23442C6.05397 7.47024 6 7.72882 6 8V9ZM11 6V7H13V6H11ZM15 6V7H16C16.1374 7 16.266 7.02717 16.3825 7.07549L16.7656 6.15176C16.5298 6.05397 16.2712 6 16 6H15ZM18 11H17V13H18V11ZM18 15H17V16C17 16.1374 16.9728 16.266 16.9245 16.3825L17.8482 16.7656C17.946 16.5298 18 16.2712 18 16V15ZM13 18V17H11V18H13Z"
                            fill="black"
                            fillOpacity="0.5"
                        />
                    </svg>
                }}
                selectedId={selected.id}
                onSelected={onTreeListSelected}
            />
            </div>
            <div className="m-1 p-1">
                <hr />
                {Object.entries(selected).length ? <div className="mb-3">
                    <span>Selected : </span>
                    <small>{selected.label}</small>
                </div> : ""}
                {create ? <form className="my-2" onSubmit={formik.handleSubmit}>


                    <div className="mb-3">
                        <label htmlFor="title" className="form-label">Topic Name</label>
                        <input value={formik.values.title} onChange={formik.handleChange} type="text" className="form-control" id="title" name="title" placeholder="Enter topic Name" />
                        {formik.touched.title && <small className="errMsg">{formik.errors.title}</small>}
                        {err.title ? <small className="error" style={{ textAlign: "end" }}>{err.title}</small> : ""}
                    </div>
                    <div className="mb-3">
                        <label htmlFor="description" className="form-label">Topic Description</label>
                        <textarea value={formik.values.description} onChange={formik.handleChange} className="form-control" id="description" name="description" rows="3" placeholder="Topic Description"></textarea>
                        {formik.touched.description && <small className="errMsg">{formik.errors.description}</small>}
                        {err.description ? <small className="error" style={{ textAlign: "end" }}>{err.description}</small> : ""}
                    </div>
                    <div className="d-flex justify-content-evenly mt-2">
                        <button type="submit" className="btn btn-primary">{update ? "Update" : "Create"}</button>
                        <button type="button" className="btn btn-danger" onClick={() => { setCreate(false); setupdate(false) }}>Cancel</button>
                    </div>
                    {success.message ? <p className="successMsg" style={{ textAlign: "center" }}>{success.message}</p> : ""}
                </form> : <div className="d-flex justify-content-center gap-1 flex-wrap">

                    <button type="button" className="btn btn-primary m-auto" onClick={() => setCreate(true)}>Create</button>
                    {Object.entries(selected).length ? <>
                        <button type="button" className="btn btn-warning m-auto" onClick={() => {
                            if (Object.entries(selected).length) {
                                setupdate(true); setCreate(true)
                            }
                        }}>Update</button>
                        <button type="button" className="btn btn-info m-auto" onClick={() => handleAdd(selected.id)}>Add to Topic</button>
                        <button type="button" className="btn btn-danger m-auto" onClick={() => { handleDelete(selected); }}>Delete</button>
                    </> : ""}
                </div>}
            </div>














            {/* {popup?.from === "navlink" ? <Select
                className="basic-single"
                classNamePrefix="select"
                name="filter"
                isClearable={true}
                isSearchable={true}
                options={topics.map(v => ({ value: v.id, label: v.title }))}
                onInputChange={e => search(e || "")}
                onChange={e => handleClick(e)}

            /> : < ul className="list-group">
                {topics.map(v => <li className="list-group-item d-flex align-items-center justify-content-between mb-2">
                    <div className="">
                        <h4 className="fs-5 mb-1 text-warning">{v.title}</h4>
                        <p className="fs-6 m-0 fw-light">{v.description}</p>
                    </div>
                    <div className="d-flex gap-2">
                        <i className='material-icons-sharp ' id="all_i" title="Edit">edit_note</i>
                        <i className='material-icons-sharp ' id="all_i" title="Open articles for this topic">share</i>
                    </div>
                </li>)}</ul>} */}



            {/* <li className="list-group-item d-flex align-items-center justify-content-between mb-2">
                    <div className="">
                        <h4 className="fs-5 mb-1 text-warning">Topic 2</h4>
                        <p className="fs-6 m-0 fw-light">Group description</p>
                    </div>
                    <div className="d-flex gap-2">
                        <i className='material-icons-sharp ' id="all_i" title="Edit">edit_note</i>
                        <i className='material-icons-sharp ' id="all_i" title="Open articles for this topic">share</i>
                    </div>
                </li>
                <li className="list-group-item d-flex align-items-center justify-content-between mb-2">
                    <div className="">
                        <h4 className="fs-5 mb-1 text-warning">Topic 3</h4>
                        <p className="fs-6 m-0 fw-light">Group description</p>
                    </div>
                    <div className="d-flex gap-2">
                        <i className='material-icons-sharp ' id="all_i" title="Edit">edit_note</i>
                        <i className='material-icons-sharp ' id="all_i" title="Open articles for this topic">share</i>
                    </div>
                </li>
            </ul>
            <div className="d-flex">
                <h5 className="m-0">Other's</h5>
            </div>
            <ul className="list-group">
                <li className="list-group-item d-flex align-items-center justify-content-between mb-2">
                    <div className="">
                        <h4 className="fs-5 mb-1 text-warning">Topic 4</h4>
                        <p className="fs-6 m-0 fw-light">Group description</p>
                    </div>
                    <div className="d-flex gap-2">
                        <i className='material-icons-sharp ' id="all_i" title="Edit">edit_note</i>
                        <i className='material-icons-sharp ' id="all_i" title="Open articles for this topic">share</i>
                    </div>
                </li>
                <li className="list-group-item d-flex align-items-center justify-content-between mb-2">
                    <div className="">
                        <h4 className="fs-5 mb-1 text-warning">Topic 5</h4>
                        <p className="fs-6 m-0 fw-light">Group description</p>
                    </div>
                    <div className="d-flex gap-2">
                        <i className='material-icons-sharp ' id="all_i" title="Edit">edit_note</i>
                        <i className='material-icons-sharp ' id="all_i" title="Open articles for this topic">share</i>
                    </div>
                </li> */}
            {/* </ul> */}
        </>
    )
}
export default TopicsList