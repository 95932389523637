import * as types from "../types"

const INITIAL_STATE = {
    topic: {},
    filter: {
        q: "",
    },
    topics: [],
    loading: false,
    error: null
}

const topic = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case types.SET_TOPIC:
            return { ...state, topic: action.payload, loading: false }
        case types.SET_TOPICS:
            return { ...state, topics: action.payload, loading: false }
        case types.SET_TOPIC_FILTER:
            return { ...state, filter: action.payload }
        case types.SET_TOPIC_LOADING:
            return { ...state, loading: true }
        case types.SET_TOPIC_ERROR:
            return { ...state, error: action.payload, loading: false }
        case types.RESET_ALL_STATE:
            return INITIAL_STATE
        default:
            return state;
    }
}
export default topic