import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Select from "react-select";
import { getArticles, searchArticles, setArticleSortBy, setArticlesType } from "../redux/actions/articles";

const options = [
    { value: '-created_at', label: 'Newest Upload' },
    { value: 'created_at', label: 'Oldest Upload' },
    { value: 'title', label: 'A - Z Title' },
    { value: '-title', label: 'Z - A Title' },
    { value: 'url', label: 'A - Z Url' },
    { value: '-url', label: 'Z - A Url' }
];

const Search = () => {
    const dispatch = useDispatch();
    const { type, id } = useParams();
    const { filter } = useSelector(state => state.article)
    React.useEffect(() => {
        console.log(type, id);

        dispatch(searchArticles(""))

        if (type === "check_text") {
            dispatch(setArticlesType({ "filters[status]": "draft", }))
        } else if (type === "audio") {
            dispatch(setArticlesType({ "filters[type]": "audio", "filters[status]": "publish" }))
        } else if (type === "video") {
            dispatch(setArticlesType({ "filters[type]": "video", "filters[status]": "publish" }))
        } else if (type === "text") {
            dispatch(setArticlesType({ "filters[type]": "image", "filters[status]": "publish" }))
        } else if (type === "topic") {
            dispatch(setArticlesType({ "filters[topic]": id }))
        } else if (type === "group") {
            dispatch(setArticlesType({ "filters[group]": id }))
        } else if (type === "url") {
            dispatch(setArticlesType({ "filters[url]": id }))
        } else {
            dispatch(setArticlesType({}))
        }
    }, [type, id]);
    React.useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            dispatch(getArticles())
        }, 200)

        return () => clearTimeout(delayDebounceFn)

    }, [filter]);

    useEffect(()=>{
        console.log(filter);
    },[filter])
    return (
        <div className="search-main d-flex justify-content-between px-4 py-2 mt-2 align-items-center" id="topbar">
            <div className="d-flex w-100 border rounded">
                <div className="w-100 d-flex rounded" style={{ flex: 1 }}>
                    <input onChange={e => { dispatch(searchArticles(e.target.value)) }} value={filter.q} className="search-input w-100" type="search" id="search" name="search" placeholder={`Search ${type}`} />
                </div>
                <div style={{
                    "width": '1px',
                    "height": "22px",
                    "background": "var(--bs-warning)",
                    "margin": "auto",
                }}>

                </div>
                <div className="">
                    <Select
                        styles={{
                            control: (baseStyles) => {
                                baseStyles.backgroundColor = "transparent"
                                return {
                                    ...baseStyles,
                                    border: "none",

                                    minWidth: window.innerWidth <= 420 ? "140px" : "200px",
                                    maxWidth: window.innerWidth <= 420 ? "140px" : "200px"
                                }
                            },
                            input: (baseStyles) => ({
                                ...baseStyles,
                                color: "inherit"
                            }),
                            option: (baseStyles, state) => ({
                                ...baseStyles,
                                color: state.isFocused ? "var(--bs-warning)" : "inherit",
                                cursor: "pointer",

                                backgroundColor: "var(--bs-gray-800)"
                            }),
                            singleValue: (baseStyles) => ({
                                ...baseStyles,
                                color: "inherit",
                            })
                            // menuList
                        }}
                        className="basic-single"
                        classNamePrefix="select"
                        defaultValue={options[0]}
                        name="filter"
                        options={options}
                        onChange={e => dispatch(setArticleSortBy(e.value))}
                    />
                </div>
            </div>
        </div>
    )
}
export default Search