import React from 'react'
import extensionDetails from '../utill/extensionDetails'
const ExtensionDownloadPopup = () => {

    const downloadExtensionFile = (extension) => {
        try {
            const url = `./extensions/${extension.fileName}`;
            console.log(url);
            const link = document.createElement("a");
            link.href = url;
            link.download = "extension.rar";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            console.error("Error downloading file:", error);
        }
    };

    return (
        <div>
            <ul className='list-group'>
                {
                    extensionDetails.map((extension, index) => {
                        return (
                            <>
                                <li key={index} className='list-group-item mb-2'>
                                    <div >
                                        <div className="d-flex justify-content-between mb-1">
                                            <h4 className="fs-6 mb-1 text-warning">Extension-{extension.version}</h4>
                                            <i className='material-icons-sharp ' id="all_i" title="Download Extension" onClick={() => {
                                                downloadExtensionFile(extension);
                                            }}>download</i>
                                        </div>
                                        <div>
                                            <p className="fs-7 w m-0 fw-light">
                                                {/* {extension.description} */}
                                            </p>
                                        </div>
                                    </div>

                                </li>
                                <img src='/media/manual.png' />
                            </>
                        )
                        
                        
                    })
                }

            </ul>
        </div>
    )
}

export default ExtensionDownloadPopup