import * as types from "../types"
import articleApi from '../../api/article';
import { getMe } from "./user";

export const searchArticles = (q) => {
    return (dispatch, getState) => {
        const { article } = getState()
        dispatch({
            type: types.SET_FILTER,
            payload: { ...article.filter, q }
        })
    }
}
export const setArticle = (data) => {
    return {
        type: types.SET_ARTICLE,
        payload: data
    }
}
export const createArticle = (values, navigate, controller, setUploading) => {
    return (dispatch) => {
        setUploading(true)
        articleApi.add(values, dispatch, controller).then(v => {

            dispatch({
                type: types.SET_ARTICLE_SUCCESS,
                payload: {
                    createArticle: "article created successfully"
                }
            })
            setTimeout(() => {
                dispatch({
                    type: types.SET_ARTICLE_SUCCESS,
                    payload: {
                    }
                })
            }, 2000);
            dispatch({
                type: types.SET_ARTICLE_UPLOAD_PROGRESS,
                payload: 0
            })
            dispatch(getArticle(v.data.id))
            navigate("/all")
        }).catch(er => {
            dispatch({
                type: types.SET_ARTICLE_ERROR,
                payload: {
                    createArticle: er.response?.data.error
                }
            })
        }).finally(() => {
            setUploading(false)
        })
        return "ihijkbugi"
    }
}
export const setArticlesType = (data) => {
    return (dispatch, getState) => {
        const { article } = getState()
        dispatch({
            type: types.SET_FILTER,
            payload: { q: article.filter.q, sort: article.filter.sort, ...data }
        })
    }
}
export const setArticleSortBy = (sort) => {
    console.log(sort);
    return (dispatch, getState) => {
        const { article } = getState()
        dispatch({
            type: types.SET_FILTER,
            payload: { ...article.filter, sort }
        })
    }
}
export const getArticles = () => {
    return (dispatch, getState) => {
        const { article } = getState();
        const queryParam = new URLSearchParams(article.filter);
        articleApi.get(queryParam.toString()).then(v => {
            dispatch({
                type: types.SET_ARTICLES,
                payload: v.data
            })
            // dispatch({
            //     type: types.SET_ARTICLE,
            //     payload: {}
            // })
            dispatch({
                type: types.SET_page,
                payload: v.data.pagination
            })
        }).catch(er => {
            dispatch({
                type: types.SET_ARTICLE_ERROR,
                payload: er.data
            })
        })
    }
}
export const getArticle = (id) => {
    return (dispatch) => {
        const queryParam = new URLSearchParams({ "filters[id]": id });
        articleApi.getById(queryParam).then(v => {
            dispatch({
                type: types.SET_ARTICLE,
                payload: v.data.items.length ? v.data.items[0] : {}
            })
        }).catch(er => {
            dispatch({
                type: types.SET_ARTICLE_ERROR,
                payload: er.data
            })
        })
    }
}
export const loadMore = (page) => {
    return (dispatch, getState) => {
        const { article } = getState();
        const queryParam = new URLSearchParams({ ...article.filter, page });
        articleApi.get(queryParam.toString()).then(v => {
            const articles = [...article.articles.items, ...v.data.items]
            console.log(articles);
            dispatch({
                type: types.SET_ARTICLES,
                payload: { items: articles, pagination: { ...article.articles.pagination, current_page: v.data.pagination?.current_page } }
            })

        }).catch(er => {
            dispatch({
                type: types.SET_ARTICLE_ERROR,
                payload: er.data
            })
        })
    }
}



