import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getMe } from "../redux/actions/user";

const useAuth = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { profile, errors } = useSelector(s => s.user)
    const [load, setLoad] = useState(true)

    React.useEffect(() => {
        dispatch(getMe())
    }, [])
    React.useEffect(() => {
        if (Object.entries(profile).length !== 0) {
            setLoad(false);
        } else {
            if (Object.entries(errors?.me || {}).length !== 0) {
                navigate("new/login")
            }
        }
    }, [profile, errors?.me])

    return {
        load
    }
}
export default useAuth