
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import group from "../api/group";
import article from "../api/article";
import { getGroups } from "../redux/actions/group";
import { setPopup } from "../redux/actions/popup";
import { getArticles, setArticle } from "../redux/actions/articles";


const DeleteType = {
    group: "group",
    article: "article"
}
const DeleteArticle = ({ popup }) => {
    const dispatch = useDispatch()
    const [er, setEr] = useState(null)
    const [success, setsuccess] = useState(null);
    const articles = useSelector(s => s.article.articles.items)


    const handleDelete = () => {
        console.log(articles)
        if (popup.from === DeleteType.group)
            group.delete(popup.data?.id).then(v => {
                document.getElementById("deletePopupClose").click()
                dispatch(setPopup({}))
                dispatch(getGroups({}))
            }).catch(er => {
                setEr(er.response?.data.error)
            })
        if (popup.from === DeleteType.article) {
            article.delete(popup.data?.id).then(v => {
                document.getElementById("deletePopupClose").click()
                const nextArticleIndex = getNextArticleIndex(popup.data?.id)
                dispatch(setPopup({}))
                dispatch(getArticles())
                if (nextArticleIndex >= 0) {
                    dispatch(setArticle(articles[nextArticleIndex]));
                }
                else {
                    dispatch(setArticle({}));
                }

            }).catch(er => {
                console.log(er)
                setEr(er.response?.data.error)
            })
        }
    }

    const getNextArticleIndex = (articleId) => {
        let index = articles.findIndex(article => article.id === articleId);
        index = ((index + 1) < articles.length) ? index + 1 : index - 1;

        return index;
    }
    return (
        <div className="modal-content text-bg-light">
            <button id="deletePopupClose" style={{ display: "none" }} type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            <div className="modal-header">
                <h1 className="modal-title fs-5 text-warning" id="deleteArticleLabel">Delete Article</h1>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
                {popup.from === DeleteType.group ? <p>Are You sure to delete this Group?</p> : ""}
                {popup.from === DeleteType.article ? <p>Are You sure to delete this article?</p> : ""}
            </div>
            <div className="modal-footer">
                <button type="button" className="btn btn-danger" onClick={() => handleDelete()}>Delete</button>
                {er && <small className="errMsg">{er.message}</small>}
            </div>
        </div>
    )
}
export default DeleteArticle