import { useNavigate, useParams } from "react-router-dom"
import { useFormik } from 'formik';
import * as Yup from "yup";
import userApi from "../api/user"
import { useState } from "react";
const ForgotePass = () => {
    const navigate = useNavigate();
    
    const { token } = useParams()
    const [att, setAtt] = useState({})

    const eamilSchema = Yup.object().shape({
        email: Yup.string().min(2).max(32).email('Invalid email').required('Required'),
    });
    const emailForm = useFormik({
        initialValues: {
            email: ''
        },

        enableReinitialize: true,
        validationSchema: eamilSchema,

        onSubmit: (values, { resetForm }) => {
            console.log(values);
            userApi.resetEmail(values).then(v => {
                console.log(v);
                setAtt({ success: "A password reset email is sent to your email. Please check your email now" })
            }).catch(er => {
                setAtt({ error: er.response?.data.error?.message })
            }).finally(() => resetForm())
        }
    })
    const ForgoteSchema = Yup.object().shape({
        new_password: Yup.string().max(32).required('Required'),
        confirm_password: Yup.string().min(8).max(32).required('Required').oneOf([Yup.ref('new_password'), null], 'Passwords must match'),
    });
    const formik = useFormik({
        initialValues: {
            new_password: '',
            confirm_password: ''
        },

        enableReinitialize: true,
        validationSchema: ForgoteSchema,

        onSubmit: (values, { resetForm }) => {
            console.log(values);
            values.token = token
            userApi.resetPass(values).then(v => {
                setAtt({ success: "Password reset SuccessFull" })
                setTimeout(() => {
                    navigate("/new/login")
                }, "1000");
            }).catch(er => {
                setAtt({ error: er.response?.data.error?.message })
            }).finally(() => resetForm())
        }
    })
    if (token) {
        return <div className="auth-background d-flex flex-colum align-items-center justify-content-center">
            <div className="logo mb-4">
                <div className="logo-details">
                    <img src="/media/yourarchive_logo.png" />
                    <h2 className="mb-0 mx-1">Your<span className="logo_name">Archiv</span>
                    </h2>
                </div>
            </div>
            <form className="auth-container text-dark" onSubmit={formik.handleSubmit}>
                <div className="auth-left"></div>
                <div className="auth-right">
                    <h3 className="text-warning">Forgot Password</h3>
                    <input autoComplete="off" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.new_password} className="auth-input" id="new_password" name="new_password" type="password" placeholder="Password" />
                    {formik.touched.new_password && <small className="errMsg">{formik.errors.new_password}</small>}
                    <input autoComplete="off" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.confirm_password} className="auth-input" id="confirm_password" name="confirm_password" type="password" placeholder="Repeat Password" />
                    {formik.touched.confirm_password && <small className="errMsg">{formik.errors.confirm_password}</small>}
                    <div className="d-flex justify-content-end my-3">
                        <button type="submit" className="btn btn-warning" id="login_btn">Change password</button>
                    </div>
                    <p className="error" style={{ textAlign: "end" }}>{att.success}</p>
                    <p className="error" style={{ textAlign: "end" }}>{att.error}</p>
                </div>
            </form>
        </div>
    }

    return (
        <div className="auth-background d-flex flex-colum align-items-center justify-content-center">
            <div className="logo mb-4">
                <div className="logo-details">
                    <img src="/media/yourarchive_logo.png" />
                    <h2 className="mb-0 mx-1">Your<span className="logo_name">Archiv</span>
                    </h2>
                </div>
            </div>
            <form className="auth-container text-dark" onSubmit={emailForm.handleSubmit}>
                <div className="auth-left"></div>
                <div className="auth-right">
                    <h3 className="text-warning">Forgot Password</h3>
                    {att.success ? (
                        <>
                            <br></br>
                            <div className="forgot-midd-box">
                                <p className="successMsg">{att.success}</p>
                            </div>
                            <p ><span className="link-button" onClick={() => { navigate('../login') }}>Click to Login</span></p>
                            <br></br>
                        </>
                    ) : (
                        <>                            
                            <p className="">Please enter your email address to search for your account.</p>
                            <input autoComplete="off" onChange={emailForm.handleChange} onBlur={emailForm.handleBlur} value={emailForm.values.email} className="auth-input" password="auth-input" id="email" name="email" type="text" placeholder="Email" />
                            {emailForm.touched.email && <small className="errMsg">{emailForm.errors.email}</small>}
                            <div className="d-flex justify-content-end my-3">
                                <button type="submit" className="btn btn-warning" id="login_btn">Send Email</button>
                            </div>                            
                            <p className="error">{att.error}</p>
                        </>
                    )}
                    
                </div>
            </form>
        </div>
    )
}
export default ForgotePass