import { useFormik } from "formik"
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux"
import { Route, Routes, useNavigate } from "react-router-dom"
import Select from "react-select"
import Groups from "../components/Groups"
import Topics from "../components/Topics"
import useCountries from "../hooks/useCountries"
import { updateMe } from "../redux/actions/user"

const ProfileSchema = Yup.object().shape({
    email: Yup.string().min(2).max(32).email('Invalid email').required('Required'),
    address: Yup.string().min(2).max(1224).required('Required'),
    city: Yup.string().min(2).max(64).required('Required'),
    country: Yup.string().min(2).max(4).required('Required'),
    first_name: Yup.string().min(2).max(64).required('Required'),
    last_name: Yup.string().min(2).max(64).required('Required'),
    state: Yup.string().min(2).max(64).required('Required'),
    user_name: Yup.string().min(2).max(64).lowercase("Should be lowercase").required('Required')
});

const Profile = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const { countries } = useCountries()
    const { email, address, city, country, first_name, last_name, state, user_name, id } = useSelector(s => s.user.profile);
    const success = useSelector(s => s.user.success);

    const formik = useFormik({
        initialValues: {
            email: email || "", address: address || "", city: city || "", country: country || "", first_name: first_name || "", last_name: last_name || "",
            state: state || "", user_name: user_name || ""
        },
        enableReinitialize: true,
        validationSchema: ProfileSchema,

        onSubmit: (values) => {
            const { email, ...acsv } = values
            dispatch(updateMe(acsv, id))
        },
    })


    return (
        <div id="upload_content" style={{ flex: 1, overflow: "auto" }}>
            <div className="row w-100 h-100 text-bg-dark" >
                <form className="col-md-6 p-4 d-flex flex-column gap-2" onSubmit={formik.handleSubmit}>
                    <h4 className="text-center text-warning">Profile Settings</h4>
                    <div ><label htmlFor="user_name" className="labels">User Name</label><input onChange={formik.handleChange} value={formik.values.user_name} id="user_name" name="user_name" type="text" className="form-control" placeholder="User name" /></div>
                    {formik.touched.user_name && <small className="errMsg">{formik.errors.user_name}</small>}
                    <div className="row w-100">
                        <div className="col-md-6" ><label htmlFor="first_name" className="labels">First Name</label><input onChange={formik.handleChange} value={formik.values.first_name} id="first_name" name="first_name" type="text" className="form-control" placeholder="First Name" /></div>
                        {formik.touched.first_name && <small className="errMsg">{formik.errors.first_name}</small>}
                        <div className="col-md-6" ><label htmlFor="last_name" className="labels">Last Name</label><input onChange={formik.handleChange} value={formik.values.last_name} id="last_name" name="last_name" type="text" className="form-control" placeholder="Last Name" /></div>
                        {formik.touched.last_name && <small className="errMsg">{formik.errors.last_name}</small>}
                    </div>
                    <div ><label htmlFor="email" className="labels">Email ID</label><input disabled value={formik.values.email} name="email" id="email" type="email" className="form-control" placeholder="Email ID" /></div>
                    {formik.touched.email && <small className="errMsg">{formik.errors.email}</small>}
                    <div ><label htmlFor="address" className="labels">Address</label><input onChange={formik.handleChange} value={formik.values.address} id="address" name="address" type="text" className="form-control" placeholder="Address" /></div>
                    {formik.touched.address && <small className="errMsg">{formik.errors.address}</small>}
                    <div ><label htmlFor="city" className="labels">city</label><input onChange={formik.handleChange} value={formik.values.city} id="city" name="city" type="text" className="form-control" placeholder="city" /></div>
                    {formik.touched.city && <small className="errMsg">{formik.errors.city}</small>}
                    <div ><label htmlFor="state" className="labels">State/Region</label><input onChange={formik.handleChange} value={formik.values.state} id="state" name="state" type="text" className="form-control" placeholder="state" /></div>
                    {formik.touched.state && <small className="errMsg">{formik.errors.state}</small>}
                    <div ><label htmlFor="country" className="labels">Country</label>
                        <div className="text-bg-light">
                            <Select
                                className="basic-single"
                                classNamePrefix="select"
                                isSearchable={true}
                                name="country"
                                id="country"
                                onBlur={() => formik.setFieldTouched("country", true)}
                                onChange={(v) => formik.setFieldValue("country", v.value)}
                                value={(countries.find(v => formik.values.country === v.value))}
                                options={countries}
                            />
                        </div>
                        {formik.touched.country && <small className="errMsg">{formik.errors.country}</small>}
                    </div>
                    <div className="mt-2 text-center"><button className="btn btn-primary profile-button" type="submit">Save Profile</button></div>
                    {success.updateMe ? <span style={{ textAlign: "center" }} className="successMsg">{success.updateMe}</span> : ""}
                </form>
                <div className="col-md-6 p-4 d-flex flex-column gap-2">
                    {/* <div className="d-flex justify-content-evenly p-2 border border-2 rounded"> */}
                    {/* <p className="btn btn-secondary m-0" onClick={() => navigate('topics')}>Topic</p> */}
                    {/* <p className="btn btn-warning m-0" onClick={() => navigate('groups')}>Groups</p>
                    </div> */}
                    <div>
                        {/* <Routes> */}
                        {/* <Route path="/groups" element={ */}
                        <Groups />
                        {/* } /> */}
                        {/* <Route path="/topics" element={<Topics />} /> */}
                        {/* </Routes> */}
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Profile