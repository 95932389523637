import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { setPopup } from "../redux/actions/popup"
import CreateGroup from "./CreateGroup"
import CreateTopic from "./CreateTopic"
import DeletePopup from "./DeletePopup"
import GroupsList from "./GroupsList"
import MemberList from "./MemberList"
import TopicsList from "./TopicsList"
import GroupUpdateOnLogin from "./GroupUpdateOnLogin"
import ExtensionDownloadPopup from "./ExtensionDownloadPopup"

const PopupsContainer = () => {
    const dispatch = useDispatch()
    const { popup } = useSelector(s => s.popup)
    useEffect(() => {
        const entry = Object.entries(popup)
        if (entry.length) {
            const elem = window.document.getElementById(popup.type + "btn");
            elem.click()
        } else {
            // document.querySelectorAll(".modal").forEach(function (element) {
            //     document.getElementById(element.id).addEventListener('hide.bs.modal', function (e) {
            //         dispatch(setPopup({}))
            //     })
            // });
        }
        console.log(popup);
    }, [popup])
    // useEffect(() => {
    //     document.querySelectorAll(".modal").forEach(function (element) {
    //         document.getElementById(element.id).addEventListener('hide.bs.modal', function (e) {
    //             dispatch(setPopup({}))
    //         })
    //     });
    // }, [])

    return (<>
        {/* create group */}
        <button style={{ display: "none" }} id="createGroupbtn" data-bs-toggle="modal" data-bs-target="#createGroup"></button>
        <div className="modal fade" id="createGroup" tabIndex="-1" aria-labelledby="createGroupLabel" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content text-bg-light">
                    <div className="modal-header">
                        <h1 className="modal-title fs-5 text-warning" id="createGroupLabel">{popup.type === "createGroup" ? popup.from === "edit" ? "Edit Group" : "Create Group" : "Create Group"}</h1>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        {popup?.type === "createGroup" ? <CreateGroup popup={popup} /> : ""}
                    </div>
                    <div className="modal-footer">
                        {/* <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                        <button type="button" className="btn btn-primary">Save changes</button> */}
                    </div>
                </div>
            </div>
        </div>

        <button style={{ display: "none" }} id="deletePopupbtn" data-bs-toggle="modal" data-bs-target="#deletePopup"></button>
        <div className="modal fade" id="deletePopup" tabIndex="-1" aria-labelledby="deletePopupLabel" aria-hidden="true">
            <div className="modal-dialog">
                {popup?.type === "deletePopup" ? <DeletePopup popup={popup} /> : ""}
                {/* <DeletePopup /> */}
            </div>
        </div>

        {/* Groups List */}
        <button style={{ display: "none" }} id="GroupsListbtn" data-bs-toggle="modal" data-bs-target="#GroupsList"></button>
        <div className="modal fade" id="GroupsList" tabIndex="-1" aria-labelledby="GroupsListLabel" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content text-bg-light">
                    <div className="modal-header">
                        <h1 className="modal-title fs-5 text-warning" id="GroupsListLabel">Groups</h1>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        {popup?.type === "GroupsList" ? <GroupsList popup={popup} /> : ""}
                    </div>
                    <div className="modal-footer">
                        {/* <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                        <button type="button" className="btn btn-primary">Save changes</button> */}
                    </div>
                </div>
            </div>
        </div>
        {/* Members List */}
        <button style={{ display: "none" }} id="membersListbtn" data-bs-toggle="modal" data-bs-target="#membersList"></button>
        <div className="modal fade" id="membersList" tabIndex="-1" aria-labelledby="membersListLabel" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content text-bg-light">
                    <div className="modal-header">
                        <h1 className="modal-title fs-5 text-warning" id="membersListLabel">Members</h1>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        {popup?.type === "membersList" ? <MemberList popup={popup} /> : ""}
                    </div>
                    <div className="modal-footer">
                        {/* <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                        <button type="button" className="btn btn-primary">Save changes</button> */}
                    </div>
                </div>
            </div>
        </div>

        {/* create topic */}
        <div className="modal fade" id="createTopic" tabIndex="-1" aria-labelledby="createTopicLabel" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content text-bg-light">
                    <div className="modal-header">
                        <h1 className="modal-title fs-5 text-warning" id="createTopicLabel">Create Topic</h1>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <CreateTopic />
                    </div>
                    <div className="modal-footer">
                        {/* <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                        <button type="button" className="btn btn-primary">Save changes</button> */}
                    </div>
                </div>
            </div>
        </div>

        {/* Topics List */}
        <button style={{ display: "none" }} id="topicsListbtn" data-bs-toggle="modal" data-bs-target="#topicsList"></button>
        <div className="modal fade" id="topicsList" tabIndex="-1" aria-labelledby="topicsListLabel" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content text-bg-light">
                    <div className="modal-header">
                        <h1 className="modal-title fs-5 text-warning" id="topicsListLabel">Topics</h1>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>

                    </div>
                    <div className="modal-body">
                        {popup?.type === "topicsList" ? <TopicsList popup={popup} /> : ""}

                    </div>
                    <div className="modal-footer">
                        {/* <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                        <button type="button" className="btn btn-primary">Save changes</button> */}
                    </div>
                </div>
            </div>
        </div>

        {/* group update on login */}

        <button style={{ display: "none" }} id="groupupdateonloginbtn" data-bs-toggle="modal" data-bs-target="#groupupdateonlogin"></button>
        <div className="modal fade" id="groupupdateonlogin" tabIndex="-1" aria-labelledby="groupupdateonloginLabel" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content text-bg-light">
                    <div className="modal-header">
                        <h1 className="modal-title fs-5 text-warning" id="groupupdateonloginLabel">New Update for groups</h1>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        {popup?.type === "groupupdateonlogin" ? <GroupUpdateOnLogin popup={popup} /> : ""}
                    </div>
                    <div className="modal-footer">
                        {/* <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                        <button type="button" className="btn btn-primary">Save changes</button> */}
                    </div>
                </div>
            </div>
        </div>


        {/* extension downlaod popup */}
        <button style={{ display: "none" }} id="extensiondownloadpopupbtn" data-bs-toggle="modal" data-bs-target="#extensiondownloadpopup"></button>
        <div className="modal fade" id="extensiondownloadpopup" tabIndex="-1" aria-labelledby="extensiondownloadpopupLabel" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content text-bg-light">
                    <div className="modal-header">
                        <h1 className="modal-title fs-5 text-warning" id="extensiondownloadpopupLabel">Download Your Archiv Extension</h1>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        {popup?.type === "extensiondownloadpopup" ? <ExtensionDownloadPopup /> : ""}
                    </div>
                    <div className="modal-footer">
                        {/* <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                        <button type="button" className="btn btn-primary">Save changes</button> */}
                    </div>
                </div>
            </div>
        </div>

    </>

    )
}

export default PopupsContainer