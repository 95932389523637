import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUsers } from "../redux/actions/user";

const useUser = () => {
    const dispatch = useDispatch()
    const { profile, auth, user, users, loading, errors, success } = useSelector(s => s.user);
    const [q, setQ] = useState("");

    useEffect(() => {
        dispatch(getUsers(q))
    }, [q])


    return { profile, auth, user, users, loading, errors, success, search: (q) => setQ(q) }

}
export default useUser;