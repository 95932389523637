import { Route, Routes } from "react-router-dom"
import ForgotePass from "../components/ForgotPass"
import Login from "../components/Login"
import Register from "../components/Register"

const Auth = () => {
    console.log('start2');
    return (
        <Routes>
            <Route path="/forgot"
                element={<ForgotePass />}
            />
            <Route path="/forgot/:token"
                element={<ForgotePass />}
            />
            <Route path="/login"
                element={<Login />}
            />
            <Route path="/register"
                element={<Register />}
            />
        </Routes>
    )
}
export default Auth