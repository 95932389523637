import * as types from "../types"

const INITIAL_STATE = {
    profile: {},
    auth: {},
    user: {},
    users: [],
    loading: false,
    errors: {},
    success: {},
}

const user = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case types.SET_USER:
            return { ...state, user: action.payload, loading: false }
        case types.SET_AUTH:
            return { ...state, auth: action.payload, loading: false }
        case types.SET_PROFILE:
            return { ...state, profile: action.payload, loading: false }
        case types.SET_USERS:
            return { ...state, users: action.payload, loading: false }
        case types.SET_USER_LOADING:
            return { ...state, loading: true }
        case types.SET_USER_ERROR:
            return { ...state, errors: action.payload, loading: false }
        case types.SET_USER_SUCCESS:
            return { ...state, success: action.payload, loading: false }
        case types.RESET_ALL_STATE:
            return INITIAL_STATE
        default:
            return state;
    }
}
export default user