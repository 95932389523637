import { topic } from "../../api/topic";
import * as types from "../types"

export const getTopics = () => {
    return (dispatch, getState) => {
        const { topic: sdv } = getState();
        const queryParam = new URLSearchParams(sdv.filter);
        topic.getMy(queryParam.toString()).then(v => {
            dispatch({
                type: types.SET_TOPICS,
                payload:v.data
            })
        }).catch(er => {
            console.er(er);
        })
    }
}