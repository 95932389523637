export const GET_USER = "GET_USER"
export const SET_USER = "SET_USER"
export const SET_AUTH = "SET_AUTH"
export const SET_PROFILE = "SET_PROFILE"
export const GET_USERS = "GET_USERS"
export const SET_USERS = "SET_USERS"
export const REGISTER_USER = "REGISTER_USER"
export const LOGIN_USER = "LOGIN_USER"
export const UPDATE_USER = "UPDATE_USER"
export const DELETE_USER = "DELETE_USER"
export const LOGOUT_USER = "LOGOUT_USER"
export const SET_USER_ERROR = "SET_USER_ERROR"
export const SET_USER_SUCCESS = "SET_USER_SUCCESS"
export const SET_USER_LOADING = "SET_USER_LOADING"


export const GET_TOPICS = "GET_TOPICS"
export const GET_TOPIC = "GET_TOPIC"
export const CREATE_TOPIC = "CREATE_TOPIC"
export const DELETE_TOPIC = "DELETE_TOPIC"
export const UPDATE_TOPIC = "UPDATE_TOPIC"
export const SET_TOPICS = "SET_TOPICS"
export const SET_TOPIC = "SET_TOPIC"
export const SET_TOPIC_ERROR = "SET_TOPIC_ERROR"
export const SET_TOPIC_LOADING = "SET_TOPIC_LOADING"
export const SET_TOPIC_FILTER = "SET_TOPIC_FILTER"


export const SET_GROUP = "SET_GROUP"
export const SET_GROUPS = "SET_GROUPS"
export const CREATE_GROUP = "CREATE_GROUP"
export const UPDATE_GROUPS = "UPDATE_GROUPS"
export const DELETE_GROUPS = "DELETE_GROUPS"
export const GET_GROUP = "GET_GROUP"
export const GET_GROUPS = "GET_GROUPS"
export const SET_GROUPS_ERROR = "GET_GROUPS_ERROR"
export const SET_GROUPS_LOADING = "SET_GROUPS_LOADING"


export const SET_ARTICLE = "SET_ARTICLE"
export const SET_FILTER = "SET_FILTER"
export const SET_ARTICLES = "SET_ARTICLES"
export const SET_page = "SET_page"
export const GET_ARTICLES = "GET_ARTICLES"
export const GET_ARTICLE = "GET_ARTICLE"
export const CREATE_ARTICLE = "CREATE_ARTICLE"
export const DELETE_ARTICLE = "DELETE_ARTICLE"
export const UPDATE_ARTICLE = "UPDATE_ARTICLE"
export const SET_ARTICLE_ERROR = "SET_ARTICLE_ERROR"
export const SET_ARTICLE_SUCCESS = "SET_ARTICLE_SUCCESS"
export const SET_ARTICLE_LOADING = "SET_ARTICLE_LOADING"
export const SORT_ARTICLES = "SORT_ARTICLES"
export const SET_ARTICLE_UPLOAD_PROGRESS = "SET_ARTICLE_UPLOAD_PROGRESS"
export const SEARCH_FOR_URL = "SEARCH_FOR_URL"


export const SET_POPUP = "SET_POPUP"
export const RESET_POPUP = "RESET_POPUP";

export const RESET_ALL_STATE = "RESET_ALL_STATE"
