import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { RESET_POPUP } from '../redux/types'
import { removePopup } from '../redux/actions/popup'
import { setPopup } from '../redux/actions/popup'

const GroupUpdateOnLogin = () => {
    const groupUpdates = useSelector((state) => {
        return state.popup
    })

    const dispatch = useDispatch();

    const navigate = useNavigate();

    console.log(groupUpdates?.popup)
    return (
        <div>
            <ul className="list-group">
                {
                    groupUpdates?.popup?.updatesInGroup?.map((el, index) => {
                        return <li key={index} className="list-group-item d-flex align-items-center justify-content-between mb-2">
                            <div data-bs-dismiss="modal" aria-label="Close" onClick={() => {
                                const elem = window.document.getElementById("groupupdateonlogin" + "btn");
                                elem.click()
                                dispatch(removePopup())
                                navigate(`/group/${el?.id}`)
                            }} style={{ cursor: "pointer" }}>
                                <h4 className="fs-6 mb-1 text-warning">
                                    {
                                        el?.name ? el.name : "-"
                                    }
                                </h4>
                            </div>
                        </li>
                    })
                }
            </ul>
            {
                groupUpdates?.popup?.groupAddedIn?.length > 0 && <>
                    <h1 className="modal-title fs-5 text-warning mb-2" >You Have been Added to new Group</h1>
                    <ul className="list-group">
                        {
                            groupUpdates?.popup?.groupAddedIn?.map((el, index) => {
                                return <li key={index} className="list-group-item d-flex align-items-center justify-content-between mb-2">
                                    <div onClick={() => {
                                        const elem = window.document.getElementById("groupupdateonlogin" + "btn");
                                        elem.click()
                                        dispatch(removePopup())
                                        navigate(`/group/${el?.id}`)
                                    }} style={{ cursor: "pointer" }}>
                                        <h4 className="fs-6 mb-1 text-warning">
                                            {
                                                el?.name ? el.name : "-"
                                            }
                                        </h4>
                                    </div>
                                </li>
                            })
                        }
                    </ul></>
            }

        </div>
    )
}

export default GroupUpdateOnLogin