import { useFormik } from "formik";
import * as Yup from "yup";
import Select from "react-select"
import { useState } from "react";
import group from "../api/group";
import { useDispatch } from "react-redux";
import { getGroups } from "../redux/actions/group";
import { setPopup } from "../redux/actions/popup";
import useUser from "../hooks/useUser"


const CreateGroup = ({ popup }) => {
    const { users, search } = useUser()
    const [att, setAtt] = useState({})
    const dispatch = useDispatch()
    const groupSchema = Yup.object().shape({
        name: Yup.string().min(2).max(32).required('Required'),
        description: Yup.string().min(8).max(1024),
        member_IDs: Yup.array().of(
            Yup.object().shape({
                value: Yup.string().required("Value required"),
                label: Yup.string()
            })
        ),
    });
    const formik = useFormik({
        initialValues: {
            name: Object.entries(popup).length ? popup.data.name : "",
            description: Object.entries(popup).length && popup.data.description ? popup.data.description : "",
            member_IDs: Object.entries(popup).length ? popup.data.group_members ? popup.data.group_members?.map(v => ({ value: v.member_id, label: "sdvsd" })) : [] : [],
        },
        enableReinitialize: true,
        validationSchema: groupSchema,

        onSubmit: (values, { resetForm }) => {
            setAtt({})
            const member_IDs = values.member_IDs.map(v => v.value)
            if (popup.from !== "edit") {
                values.member_IDs = member_IDs
            } else {
                const { member_IDs, ...dsvsd } = values
                values = dsvsd
            }

            const aic = popup.from === "edit" ? group.update(popup.data.id, values) : group.create(values)
            aic.then(v => {
                setAtt({
                    success: {
                        message: "Group created successfully"
                    }
                })
                document.getElementById("createGroupclose").click()
                dispatch(setPopup({}))
                dispatch(getGroups())

            }).catch(er => {
                console.error(er.response.data);
                setAtt({
                    error: {
                        // message: "Something went wrong",
                        ...(er.response ? er.response.data.error : {})
                    }
                })
                resetForm()
            }).finally(() => {
                setTimeout(() => {
                    setAtt({
                    })
                }, 3000)
            })
        }
    })
    return (
        <form className="g-3" onSubmit={(e) => formik.handleSubmit(e)}>
            <button id="createGroupclose" style={{ display: "none" }} type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            <div className="mb-3">
                <label htmlFor="name" className="form-label">Group Name</label>
                <input type="text" value={formik.values.name} onChange={formik.handleChange} className="form-control" id="name" name="name" placeholder="Enter group name" />
                {formik.touched.name && <small className="errMsg">{formik.errors.name}</small>}
            </div>
            <div className="mb-3">
                <label htmlFor="description" className="form-label">Group Description</label>
                <textarea value={formik.values.description} onChange={formik.handleChange} className="form-control" id="description" name="description" rows="3"></textarea>
                {formik.touched.description && <small className="errMsg">{formik.errors.description}</small>}
            </div>
            {popup.from !== "edit" ? <div className="mb-3">
                <label htmlFor="member_IDs" className="labels">{popup.from === "edit" ? "Edit" : "Add"} Members</label>
                <Select
                    className="basic-single"
                    classNamePrefix="select"
                    isMulti
                    isSearchable={true}
                    name="member_IDs"
                    options={users.map(v => ({ value: v.id, label: v.email }))}
                    value={formik.values.member_IDs}
                    onChange={v => formik.setFieldValue("member_IDs", v)}
                    onInputChange={q => search(q)}
                />
                {formik.touched.member_IDs && <small className="errMsg">{formik.errors.member_IDs}</small>}
            </div> : ""}

            <div className="mb-3">
                <button type="submit" className="btn btn-primary">{popup.from === "edit" ? "Edit" : "Create"} Group</button>
                {att.error?.message && <small className="errMsg">{att.error.message}</small>}
                {att.success?.message && <small className="successMsg">{att.success?.message}</small>}
            </div>
        </form>
    )
}
export default CreateGroup