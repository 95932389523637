import user from '../../api/user';
import popup from '../../api/popup';
import * as types from '../types';

const getUserById = (id) => {
    return (dispatch) => {
        dispatch({
            type: types.SET_USER_LOADING
        })
        user.getUserById(id).then(v => {
            dispatch({
                type: types.SET_USER,
                payload: v.data
            })
        }).catch(er => {
            dispatch({
                type: types.SET_USER_ERROR,
                payload: er.response?.data
            })
        })
    }
}

const getUsers = (q) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_USER_LOADING
        })
        user.getUsers(q).then(v => {
            dispatch({
                type: types.SET_USERS,
                payload: v.data.items
            })
        }).catch(er => {
            dispatch({
                type: types.SET_USER_ERROR,
                payload: er.response?.data
            })
        })
    }
}
const registerUser = (values) => {
    return (dispatch) => {
        dispatch({
            type: types.SET_USER_LOADING
        })
        dispatch({
            type: types.SET_USER_ERROR,
            payload: {}
        })
        dispatch({
            type: types.SET_AUTH,
            payload: {}
        })
        user.register(values).then(v => {
            dispatch({
                type: types.SET_AUTH,
                payload: v.data
            })
        }).catch(er => {
            dispatch({
                type: types.SET_USER_ERROR,
                payload: { register: er.response?.data.error }
            })
        })
    }
}
const loginUser = (values) => {
    return (dispatch, getState) => {
        const { user: userState } = getState()
        dispatch({
            type: types.SET_USER_LOADING
        })
        dispatch({
            type: types.SET_USER_ERROR,
            payload: { ...userState.error, login: null }
        })
        user.login(values).then(v => {
            dispatch({
                type: types.SET_AUTH,
                payload: v.data
            })
            dispatch({
                type: types.SET_USER_SUCCESS,
                payload: { login: "user login successfull" }
            })

            popup.onLogin().then((res) => {
                console.log(res, "this is what is want")
                if (res?.data?.updatedetails?.length || res?.data?.updateInGroup?.length) {
                    dispatch({
                        type: types.SET_POPUP,
                        payload: {
                            type: "groupupdateonlogin",
                            groupAddedIn: res?.data?.updatedetails,
                            updatesInGroup: res?.data?.updateInGroup
                        }
                    })
                }

            }).catch((err) => {
                console.log(err)
            })

        }).catch(er => {
            dispatch({
                type: types.SET_USER_ERROR,
                payload: { login: er.response?.data?.error }
            })
        })
    }
}
const verifyloginUser = (values) => {
    return (dispatch, getState) => {
        const { user: userState } = getState()
        dispatch({
            type: types.SET_USER_LOADING
        })
        dispatch({
            type: types.SET_USER_ERROR,
            payload: { ...userState.error, login: null }
        })
        user.verifylogin(values).then(v => {
            dispatch({
                type: types.SET_AUTH,
                payload: v.data
            })
            dispatch({
                type: types.SET_USER_SUCCESS,
                payload: { login: "user login successfull" }
            })

        }).catch(er => {
            dispatch({
                type: types.SET_USER_ERROR,
                payload: { login: er.response?.data?.error }
            })
        })
    }
}
const getMe = () => {
    return (dispatch) => {
        dispatch({
            type: types.SET_USER_LOADING
        })
        user.me().then(v => {
            dispatch({
                type: types.SET_PROFILE,
                payload: v.data
            })
        }).catch(er => {
            dispatch({
                type: types.SET_USER_ERROR,
                payload: { me: er.response?.data }
            })
        })
    }
}
const logOut = (navigate) => {
    return (dispatch) => {
        dispatch({
            type: types.SET_USER_LOADING
        })
        user.logout().then(v => {
            dispatch({
                type: types.SET_PROFILE,
                payload: {}
            })
            dispatch({
                type: types.SET_AUTH,
                payload: {}
            })
            dispatch({
                type: types.SET_USER_SUCCESS,
                payload: {}
            })
            dispatch({
                type: types.RESET_ALL_STATE
            })
            // dispatch({
            //     type: types.RESET_POPUP
            // })
            console.log(v);
            navigate('/new/login')
        }).catch(er => {
            dispatch({
                type: types.SET_USER_ERROR,
                payload: er.response?.data
            })
        })
    }
}
const updateMe = (values, id) => {
    return (dispatch, getState) => {
        const { user: userState } = getState()
        dispatch({
            type: types.SET_USER_LOADING
        })
        dispatch({
            type: types.SET_USER_ERROR,
            payload: { ...userState.error, updateMe: null }
        })
        user.updateMe(values, id).then(v => {
            dispatch(getMe());
            dispatch({
                type: types.SET_USER_SUCCESS,
                payload: { updateMe: "Profile Update Success" }
            })
        }).catch(er => {
            dispatch({
                type: types.SET_USER_ERROR,
                payload: { updateMe: er.response?.data.error }
            })
        })
    }
}
const deleteMe = () => {
    return (dispatch) => {
        dispatch({
            type: types.SET_USER_LOADING
        })
        user.deleteMe().then(v => {
            dispatch({
                type: types.SET_PROFILE,
                payload: {}
            })
            dispatch({
                type: types.SET_AUTH,
                payload: {}
            })
        }).catch(er => {
            dispatch({
                type: types.SET_USER_ERROR,
                payload: er.response?.data
            })
        })
    }
}

export {
    getUserById, getUsers, registerUser, loginUser, verifyloginUser, logOut, getMe, deleteMe, updateMe
}