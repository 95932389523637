import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import article from "../api/article"
import { getArticle, getArticles } from "../redux/actions/articles"
import { getGroups } from "../redux/actions/group"
import { setPopup } from "../redux/actions/popup"

const GroupsList = ({ popup }) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { groups, loading, error } = useSelector(s => s.group)
    const { profile } = useSelector(s => s.user)
    React.useEffect(() => {
        dispatch(getGroups())
    }, [])

    const handleShare = (id, data) => {
        // share api here
        article.shareToGroup(id, [data?.id]).then(() => {
            dispatch(getArticle(popup.data.id))
            navigate(`/group/${id}`)
            // dispatch(getGroups())
        })
        console.log("handle share", id, data);
    }

    if (loading) return <dir>Loading..</dir>
    if (error) return <dir>Error</dir>

    return (
        <>
            <ul className="list-group">
                {groups.map(v => {
                    // if (v.admin_id === profile.id) {
                    return <li key={v.id} className="list-group-item d-flex align-items-center justify-content-between mb-2">
                        <div className="">
                            <h4 className="fs-5 mb-1 text-warning">{v.name}</h4>
                            <p className="fs-6 m-0 fw-light">{v.description}</p>
                        </div>
                        <div className="d-flex gap-2">
                            {popup?.from === 'view' ? <>
                                <i className='material-icons-sharp ' id="all_i" title="Show members" onClick={() => dispatch(setPopup({
                                    type: "membersList",
                                    from: "group",
                                    data: v
                                }))}>group_add</i>
                                <i className='material-icons-sharp ' id="all_i" title="Share article to this group" onClick={() => popup && handleShare(v.id, popup.data)}>share</i>
                            </> : <>
                                {
                                    v.admin_id === profile.id && <>
                                        <i className='material-icons-sharp ' id="all_i" title="Show members" onClick={() => dispatch(setPopup({
                                            type: "membersList",
                                            from: "group",
                                            data: v
                                        }))}>group_add</i>
                                        <i className='material-icons-sharp ' id="all_i" title="Open articles" onClick={() => dispatch(setPopup({
                                            type: "deletePopup",
                                            from: "group",
                                            data: v
                                        }))}>delete</i>
                                        <i className='material-icons-sharp ' id="all_i" title="Edit Group" onClick={() => dispatch(setPopup({
                                            type: "createGroup",
                                            from: "edit",
                                            data: v
                                        }))}>edit_note</i>
                                    </>
                                }
                            </>}
                        </div>
                    </li>
                    // } else {
                    //     return null
                    // }
                })}
                <li><hr /></li>
                {/* {groups.map(v => {
                    if (v.admin_id !== profile.id) {
                        return <li key={v.id} className="list-group-item d-flex align-items-center justify-content-between mb-2">
                            <div className="">
                                <h4 className="fs-5 mb-1 text-warning">{v.name}</h4>
                                <p className="fs-6 m-0 fw-light">{v.description}</p>
                            </div>
                        </li>
                    } else {
                        return null
                    }
                })} */}
            </ul>
        </>
    )
}
export default GroupsList