import { useFormik } from "formik";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Select from "react-select"
import * as Yup from "yup";
import { createArticle, getArticle } from "../redux/actions/articles";
import { articleSchema } from "../utill/formSchemas"
import articleApi from '../api/article';
import * as types from "../redux/types"
import axiosCustom from "../api/axios";
import axios from "axios";

const Upload = () => {
    const [uploading, setUploading] = useState(false)
    const article = useSelector(s => s.article)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const ref = useRef()
    const [err, setErr] = useState("")

    useEffect(() => {
        if (uploading)
            ref.current = window.document.getElementById("cancel-button")
    }, [uploading])

    const source = axios.CancelToken.source();

    const formik = useFormik({
        initialValues: {
            title: '',
            description: '',
            date: '',
            file: null,
            url: "",
            topics: [],
            groups: []
        },
        validationSchema: articleSchema,

        onSubmit: ({ topics, groups, ...values }, { resetForm }) => {
            function getFormData(object) {
                const formData = new FormData();
                Object.keys(object).forEach(key => formData.append(key, object[key]));
                return formData;
            }
            setUploading(true)
            axiosCustom({
                method: "post",
                url: `/article`,
                data: getFormData(values),
                cancelToken: source.token,
                onUploadProgress: e => {
                    const percentCompleted = Math.round(
                        (e.loaded * 100) / e.total
                    );
                    dispatch({ type: types.SET_ARTICLE_UPLOAD_PROGRESS, payload: percentCompleted })
                }
            }).then(v => {
                dispatch({
                    type: types.SET_ARTICLE_SUCCESS,
                    payload: {
                        createArticle: "article created successfully"
                    }
                })
                setTimeout(() => {
                    dispatch({
                        type: types.SET_ARTICLE_SUCCESS,
                        payload: {
                        }
                    })
                }, 2000);

                dispatch(getArticle(v.data.id))
                navigate("/all")
                resetForm()
            }).catch(er => {
                dispatch({
                    type: types.SET_ARTICLE_ERROR,
                    payload: {
                        createArticle: er.response?.data.error
                    }
                })
            }).finally(() => {
                setUploading(false)
            })
            ref.current?.addEventListener('click', (event) => {
                source.cancel("Request canceled by user.");
                setErr("Upload canceled")
                setTimeout(() => setErr(""), 5000)
                dispatch({
                    type: types.SET_ARTICLE_UPLOAD_PROGRESS,
                    payload: 0
                })
            })
        }
    })

    return (
        <div className="px-4 d-flex align-items-center justify-content-center text-bg-dark" id="upload_content" style={{ flex: 1, overflow: "auto" }}>
            <form onSubmit={formik.handleSubmit} className="card text-bg-light p-4" style={{ width: "520px" }}>
                <h2 className="text-uppercase text-center text-warning font-weight-bold">Upload</h2>
                <div className="my-2">
                    <label htmlFor="file" className="btn btn-primary w-100" >Select File</label>
                    <input onChange={v => formik.setFieldValue("file", v.target.files[0])} className="d-none" id="file" name="file" type="file" />
                    {formik.touched.file && <small className="errMsg">{formik.errors.file}</small>}
                    {!formik.errors.file && article.errors?.createArticle?.file ? <small className="errMsg" style={{ textAlign: "end" }}>{article.errors?.createArticle?.file}</small> : ""}
                </div>

                {formik.values.file && <div className="file-preview">
                    <p className="text-file m-0" id="filename">{formik.values.file?.name}</p>
                </div>}
                <div className="my-2">
                    <label className="text-muted" htmlFor="title">Title</label>
                    <input value={formik.values.title} onChange={formik.handleChange} className="form-control" type="text" id="title" name="title" />
                    {formik.touched.title && <small className="errMsg">{formik.errors.title}</small>}
                    {!formik.errors.title && article.errors?.createArticle?.title ? <small className="errMsg" style={{ textAlign: "end" }}>{article.errors?.createArticle?.title}</small> : ""}
                </div>
                <div className="my-2">
                    <label className="text-muted" htmlFor="description">Description</label>
                    <textarea value={formik.values.description} onChange={formik.handleChange} className="form-control" id="description" name="description"></textarea>
                    {formik.touched.description && <small className="errMsg">{formik.errors.description}</small>}
                    {!formik.errors.description && article.errors?.createArticle?.description ? <small className="errMsg" style={{ textAlign: "end" }}>{article.errors?.createArticle?.description}</small> : ""}
                </div>
                <div className="my-2">
                    <label className="text-muted" htmlFor="url">URL</label>
                    <input value={formik.values.url} onChange={formik.handleChange} className="form-control" type="text" id="url" name="url" />
                    {formik.touched.url && <small className="errMsg">{formik.errors.url}</small>}
                    {article.errors?.createArticle?.url ? <small className="errMsg" style={{ textAlign: "end" }}>{article.errors?.createArticle?.url}</small> : ""}
                </div>
                <div className="my-2">
                    <label className="text-muted" htmlFor="date">Date Created</label>
                    <div className="date-upload">
                        <input value={formik.values.date} onChange={formik.handleChange} className="form-control" type="date" id="data" name="date" />
                        {formik.touched.date && <small className="errMsg">{formik.errors.date}</small>}
                        {article.errors?.createArticle?.date ? <small className="errMsg" style={{ textAlign: "end" }}>{article.errors?.createArticle?.date}</small> : ""}
                    </div>
                </div>
                {/* <div className="my-2">
                    <label className="text-muted" htmlFor="topics">Add topics</label>
                    <Select
                        defaultValue={[options[2], options[1]]}
                        isMulti
                        id="topics"
                        name="topics"
                        options={options}
                        value={formik.values.topics}
                        onChange={v => formik.setFieldValue("topics", v)}
                        className="basic-multi-select"
                        classNamePrefix="select form-control"
                    />
                    {formik.touched.topics && <small className="errMsg">{formik.errors.topics}</small>}
                    {article.errors?.createArticle?.topics ? <small className="errMsg" style={{ textAlign: "end" }}>{article.errors?.createArticle?.topics}</small> : ""}
                </div>
                <div className="my-2">
                    <label className="text-muted" htmlFor="groups">Add groups</label>
                    <Select
                        defaultValue={[options[2], options[3]]}
                        isMulti
                        id="groups"
                        name="groups"
                        options={options}
                        value={formik.values.groups}
                        onChange={v => formik.setFieldValue("groups", v)}
                        className="basic-multi-select"
                        classNamePrefix="select form-control"
                    />
                    {formik.touched.groups && <small className="errMsg">{formik.errors.groups}</small>}
                    {article.errors?.createArticle?.groups ? <small className="errMsg" style={{ textAlign: "end" }}>{article.errors?.createArticle?.groups}</small> : ""}
                </div> */}
                {article.uploadProgress ? <div className="progress my-2" role="progressbar" aria-label="Basic example" aria-valuenow={article.uploadProgress} aria-valuemin="0" aria-valuemax="100">
                    <div className="progress-bar" style={{ "width": `${article.uploadProgress}%` }}>{article.uploadProgress}%</div>
                </div> : ""}

                {!uploading && <button id="btn-upload" className="btn btn-secondary" type="submit">UPLOAD</button>}
                {<button style={{ display: uploading ? "block" : "none" }} ref={ref} id="cancel-button" className="btn btn-secondary" type="button">CANCEL</button>}

                {err ? <p className="error" style={{ textAlign: "end" }}>{err}</p> : ""}
                {article.errors?.createArticle ? <p className="error" style={{ textAlign: "end" }}>{article.errors?.createArticle?.message}</p> : ""}

                {article.success?.createArticle ? <p className="successMsg" style={{ textAlign: "center" }}>{article.success?.createArticle}</p> : ""}
            </form>
        </div >
    )
}
export default Upload