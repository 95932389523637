import List from "./List"
import Search from "./Search"

const Listing = ({open}) => {
    return (
        <>
            <div className={`listing-container d-flex flex-column text-bg-dark ${open ? "d-none" : ""}`}>
                <Search />
                <div className="my-2" style={{
                    width: "80%",
                    background: "var(--bs-warning)",
                    height: "2px",
                    margin: "auto",
                    borderRadius: "50%"
                }}></div>

                <List />
            </div>
        </>
    )
}
export default Listing