import axios from './axios';


const group = {
    get: (q) => {
        return axios.get(`/groups?${q}`);
    },
    getOwnGroup:()=>{
        return axios.get(`/groups/own`);
    },
    getById: (id) => {
        return axios.get(`/group/${id}`);
    },
    create: (data) => {
        return axios.post(`/create-group`, data);
    },
    addMembers: (id, member_IDs) => { //array of users IDs
        return axios.post(`/add-member/${id}`, { member_IDs });
    },
    update: (id, data) => {
        return axios.patch(`/update-group/${id}`, data);
    },
    delete: (id) => {
        return axios.delete(`/remove-group/${id}`);
    },
    deleteMember: ({ group_id, member_id }) => {
        return axios.delete(`/remove-member/${group_id}/${member_id}`);
    },

};

export default group;