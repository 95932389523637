import * as types from "../types"
import group from "../../api/group"
export const getGroups = () => {
    return (dispatch) => {
        dispatch({
            type: types.SET_GROUPS_LOADING,
        })
        group.getOwnGroup().then(v => {
            console.log(v);
            dispatch({
                type: types.SET_GROUPS,
                payload: v?.data?.data
            })
        }).catch(er => {
            dispatch({
                type: types.SET_GROUPS_ERROR,
                payload: er.response?.data.error
            })
        })

    }
}