import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Route, Routes } from "react-router-dom"
import { Provider } from "react-redux";
import App from './App';
import store from "./redux/store.js";
import './index.css';
import './styles/component.css';
import './styles/page.css';
import 'bootstrap/dist/js/bootstrap.bundle.min'


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <Routes>
          <Route path="/*" element={<App />} />
        </Routes>
      </Provider>
    </BrowserRouter>
  // </React.StrictMode>
);
