import { useNavigate } from "react-router-dom"
import { useFormik } from 'formik';
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { registerUser } from "../redux/actions/user";
import { useEffect } from "react";
import { SignupSchema } from "../utill/formSchemas";

const Register = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const user = useSelector(state => state.user)
    

    const formik = useFormik({
        initialValues: {
            // username: '',
            email: '',
            password: '',
            cPassword: ''
        },

        enableReinitialize: true,
        validationSchema: SignupSchema,

        onSubmit: (values) => {
            dispatch(registerUser({ email: values.email, password: values.password }))
        }
    })

    return (
        <div className="auth-background d-flex flex-colum align-items-center justify-content-center">
            <div className="logo mb-4">
                <div className="logo-details">
                    <img src="/media/yourarchive_logo.png" />
                    <h2 className="mb-0 mx-1">Your<span className="logo_name">Archiv</span>
                    </h2>
                </div>
            </div>
            <form className="auth-container text-dark" onSubmit={formik.handleSubmit}>
                <div className="auth-left"></div>
                <div className="auth-right">
                    <h3 className="text-warning">Register</h3>
                    {user.auth?.status === "pending" ? (
                        <>
                            <br></br>
                            <div className="regis-midd-box">
                                <p className="error" style={{ textAlign: "end" }}>An confirmation email is sent to your email</p>
                            </div>
                            <p ><span className="link-button" onClick={() => { navigate('../login') }}>Click to Login</span></p>
                            <br></br>
                        </>
                    ) : (
                        <>
                            <p className="">Register a new account and start saving your online articles.</p>
                            {/* <input autoComplete="off" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.username} className="auth-input" id="username" name="username" type="text" placeholder="User Name" />
                            {formik.touched.username && <small className="errMsg">{formik.errors.username}</small>} */}
                            <input autoComplete="off" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.email} className="auth-input" password="auth-input" id="email" name="email" type="text" placeholder="Email" />
                            {formik.touched.email && <small className="errMsg">{formik.errors.email}</small>}
                            <input autoComplete="off" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.password} className="auth-input" id="password" name="password" type="password" placeholder="Password" />
                            {formik.touched.password && <small className="errMsg">{formik.errors.password}</small>}
                            <input autoComplete="off" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.cPassword} className="auth-input" id="cPassword" name="cPassword" type="password" placeholder="Repeat Password" />
                            {formik.touched.cPassword && <small className="errMsg">{formik.errors.cPassword}</small>}
                            <div className="d-flex justify-content-end my-3">
                                <button type="submit" className="btn btn-warning" id="login_btn">Register</button>
                            </div>                            
                            <p className="error" style={{ textAlign: "end" }}>{user.errors.register ? user.errors.register?.message : ""}</p>
                            <p >Already have an account? <span className="link-button" onClick={() => { navigate('../login') }}>Login</span></p>
                        </>
                    )}
                    
                </div>
            </form>
        </div>
    )
}
export default Register