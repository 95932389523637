export const SUPPORTED_FORMATS = [
    "image/jpeg", "image/png", "audio/mp3", "audio/mpeg", "audio/wav", "video/webm", "video/mp4", "application/pdf"
]
export const SUPPORTED_AUDIO_FORMATS = [
    "audio/mp3", "audio/mpeg", "audio/wav",
]
export const SUPPORTED_IMAGE_FORMATS = [
    "image/jpeg", "image/png",
]
export const SUPPORTED_DOC_FORMATS = [
    "application/pdf"
]
export const SUPPORTED_VIDEO_FORMATS = [
    "video/mp4"
]

/* The line `// export const API_BASE_URL = "http://localhost:5000/api";` is a commented out line of
code that defines a constant variable named `API_BASE_URL` with the value of
`"http://localhost:5000/api"`. This variable is likely used to store the base URL for an API that
the code interacts with. By commenting out this line, the code is not currently using this variable
and is likely using a different URL for the API. */
// export const API_BASE_URL = "http://localhost:5000/api";
// export const API_BASE_URL = "http://yourarchiv.com/api";

// export const API_BASE_URL = "http://alpha.yourarchiv.com/api";
// export const API_BASE_URL = "http://localhost:5200/api";

export const API_BASE_URL = "https://yourarchiv.com/api";